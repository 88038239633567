import { AxiosResponse } from 'axios'
import axiosInstance from '../axios'
import { IBalanceResponse, IBlockResponse, IGetLedgerAssetParams } from './balanceType'
import { ILedgerAsset, IWallet } from '../../interfaces'
import { IResponseTemplate } from '../types'

export const getBalanceByLedger = (id: string) => {
  return new Promise<AxiosResponse<IBalanceResponse>>((resolve, reject) => {
    axiosInstance
      .get('/balances/total', { params: { ledgerAssetId: id } })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const getBlockInfo = () => {
  return new Promise<AxiosResponse<IBlockResponse>>((resolve, reject) => {
    axiosInstance
      .get('/private-tokens/block-info')
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const getLedgerAssets = (params?: IGetLedgerAssetParams) => {
  return new Promise<AxiosResponse<IResponseTemplate<ILedgerAsset[]>>>((resolve, reject) => {
    axiosInstance
      .get('/ledger-assets', {
        params: {
          withBalance: 'TRUE',
          size: 100,
          ...params,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const getWalletData = (params?: any): Promise<AxiosResponse<IResponseTemplate<IWallet[]>>> =>
  axiosInstance.get('/wallets', {
    params: {
      ...params,
    },
  })
