import React, { Component } from 'react'
import { Stack } from 'react-bootstrap'
import Select, { OptionProps, Props } from 'react-select'
import { marketOrderStatusBadgeBackground } from '../../helper/color'

const CustomOption = ({ innerProps, data }: { innerProps: OptionProps; data: any }) => (
  <Stack
    {...innerProps}
    direction='horizontal'
    className='p-2 btn btn-outline-primary border-0 no-radius'
    gap={2}
  >
    <span className={`fs-7 fw-bold text-${marketOrderStatusBadgeBackground(data.value)}`}>
      {data?.label}
    </span>
  </Stack>
)

const MarketOrderStatusSelect: React.FC<Props> = (props) => {
  const options = [
    {
      label: 'LISTED',
      value: 'LISTED',
    },
    {
      label: 'EXECUTED',
      value: 'EXECUTED',
    },
    {
      label: 'CANCELED',
      value: 'CANCELED',
    },
    {
      label: 'EXPIRED',
      value: 'EXPIRED',
    },
  ]

  return (
    <Select
      isSearchable={true}
      placeholder='Select status'
      components={{ Option: CustomOption as never }}
      options={options}
      {...props}
    />
  )
}

export default MarketOrderStatusSelect
