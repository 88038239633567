import { AxiosError } from 'axios'
import React from 'react'
import { Form, Button, Container, Col, Row, Spinner, Alert, InputGroup } from 'react-bootstrap'
import { FieldValues, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import axiosInstance from '../api/axios'
import { ReactComponent as Logo } from '../assets/images/logo-vertical.svg'
import useUserStore from '../stores/userStore'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { AiOutlineEye } from 'react-icons/ai'
import { BsFlagFill } from 'react-icons/bs'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    accountId: yup.string().required(),
  })
  .required()

function Login() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()

  const { setUser } = useUserStore()

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)
    setErrorMessage(null)

    try {
      const response = await axiosInstance.post('/auth/login', {
        email: data.email,
        password: data.password,
        slug: data.accountId,
      })

      localStorage.setItem('token', response.data.accessToken)
      localStorage.setItem('refreshToken', response.data.refreshToken)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      setUser(response.data.user)
      navigate('/dashboard')
      toast.success('Login Successfully')
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.message || 'Something went wrong')
      }
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <Container>
        <Row className='justify-content-end'>
          <Col md={3}>
            <a className='btn btn-link' target='blank' href='https://forms.gle/3FKErMwccH7eqqV66'>
              <BsFlagFill />
              <span className='ml-2' style={{ marginLeft: 4 }}>
                Report a Bug
              </span>
            </a>
          </Col>
        </Row>
      </Container>
      <Container
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <Form style={{ width: '400px', marginBottom: '132px' }} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col
              xs={{
                offset: 3,
                span: 6,
              }}
            >
              <Logo />
            </Col>
          </Row>
          <h5 className='text-center mb-4'>Hey there! please login to continue.</h5>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              {...register('email', { required: true })}
              onChange={(e) => {
                setErrorMessage(null)
                setValue('email', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type='password'
                placeholder='Password'
                {...register('password', { required: true })}
                onChange={(e) => {
                  setErrorMessage(null)
                  setValue('password', e.target.value)
                }}
              />
              <Button
                variant='outline-secondary'
                id='reveal'
                onClick={(e) => {
                  e.preventDefault()
                  const input = document.getElementById('formBasicPassword')
                  if (input) {
                    input.setAttribute(
                      'type',
                      input.getAttribute('type') === 'password' ? 'text' : 'password',
                    )
                  }
                }}
              >
                <AiOutlineEye />
              </Button>
            </InputGroup>

            <Form.Text className='text-danger'>{errors.password?.message?.toString()}</Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='formAccountId'>
            <Form.Label>Account ID</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter account ID'
              {...register('accountId', { required: true })}
              onChange={(e) => {
                setErrorMessage(null)
                setValue('accountId', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.accountId?.message?.toString()}</Form.Text>
          </Form.Group>

          {errorMessage && errorMessage === 'Unauthorized' && (
            <Alert variant='danger' className='alert-sm'>
              Incorrect username and password
            </Alert>
          )}

          <div className='d-grid gap-2 mt-4'>
            <Button variant='primary' type='submit' disabled={isLoading}>
              {isLoading ? (
                <Spinner animation='border' size='sm' role='status' aria-hidden='true' />
              ) : (
                'Login'
              )}
            </Button>
          </div>

          <Link to={'/forgot-password'} className='mt-3 text-center w-100 d-block'>
            Forgot Password
          </Link>
        </Form>
      </Container>
    </>
  )
}

export default Login
