import React, { useState } from 'react'
import { Card, Col, Row, Spinner, Table, Button, Form, Modal, Stack, Image } from 'react-bootstrap'
import { ILedgerAsset, ITransaction, IWallet, TransactionType } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import { HiPlus } from 'react-icons/hi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import axiosInstance from '../api/axios'
import * as yup from 'yup'
import checked from '../assets/images/checked.png'
import { formatDateWithTimezone, formatter, getOffsetTimezone } from '../helper'
import WalletSelect from '../components/Select/WalletSelect'
import CurrencyInput from '../components/Input/CurrencyInput'
import DateRangePicker from '../components/Input/DateRangePicker'
import { addDays } from 'date-fns'
import moment from 'moment'
import { exportTransactionToCSV } from '../api/transaction/transaction'
import { getWalletData } from '../api/balance/balance'
import PopoverComponent from '../components/PopoverComponent'
import { BsEye } from 'react-icons/bs'

const TransferTable = (props: {
  list: ITransaction[]
  handleClickView: (data: ITransaction) => void
}) => {
  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Amount</th>
          <th>Asset</th>
          <th style={{ maxWidth: '150px' }}>Sender Wallet Username</th>
          <th style={{ maxWidth: '150px' }}>Receiver Wallet Username</th>
          <th>Created By</th>
          <th>Created At ({getOffsetTimezone()})</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((transaction, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={transaction.id} className='text-nowrap' />
            </td>
            <td className='fw-bold text-primary'>{formatter(transaction.amount)}</td>
            <td>{transaction.ledgerAsset.symbol}</td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {transaction.senderWallet?.user.username}
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {transaction.receiverWallet?.user.username}
            </td>
            <td>
              {' '}
              <PopoverComponent
                text={transaction.createdByUser?.email || ''}
                className='max-width-300 text-nowrap'
              />
            </td>
            <td>{formatDateWithTimezone(transaction.createdAt)}</td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickView(transaction)
                }}
              >
                <BsEye />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const TransactionDetailModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  transactionData?: ITransaction
}) => {
  const { onHide, transactionData } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='transfer-detail-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='transfer-detail-modal'>
          <h5 className='m-0 fw-bold'>Transfer Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={transactionData?.amount}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Asset</Form.Label>
          <Form.Control disabled={true} type='text' value={transactionData?.ledgerAsset.symbol} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Type</Form.Label>
          <Form.Control disabled={true} type='text' value={transactionData?.type} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={transactionData?.createdByUser?.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Account Email</Form.Label>
          <Form.Control disabled={true} type='text' value={transactionData?.createdByUser?.email} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Receiver Wallet Id</Form.Label>
          <Form.Control disabled={true} type='text' value={transactionData?.receiverWallet?.id} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Receiver Wallet Username</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={transactionData?.receiverWallet?.user.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Receiver Wallet Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={transactionData?.receiverWallet?.user.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Time of Request</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(transactionData?.createdAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

const transferSchema = yup
  .object({
    amount: yup.string().required(),
    senderWalletId: yup.string().required(),
    receiverWalletId: yup.string().required(),
    ledgerAssetId: yup.string().required(),
  })
  .required()

const TransferModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  wallets: IWallet[] | undefined
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isTransferComplete, setIsTransferComplete] = React.useState(false)
  const [receiverWallets, setReceiverWallets] = React.useState<IWallet[]>([])
  const [senderWallets, setSenderWallets] = React.useState<IWallet[]>([])

  const { data: ledgerAssets } = usePagination<ILedgerAsset>({
    queryKey: ['getLedgerAssetsTransfer'],
    url: '/ledger-assets',
    handlePageParams: false,
    pageSize: 100,
    queryParams: {
      withBalance: 'TRUE',
    },
    mapDataFn: (ledgerAssets) =>
      ledgerAssets.map((ledgerAsset: ILedgerAsset) => ({
        ...ledgerAsset,
        value: ledgerAsset.id,
        label: ledgerAsset.symbol,
      })),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setModalShow, wallets } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(transferSchema),
  })

  const senderWalletId = watch('senderWalletId')
  const receiverWalletId = watch('receiverWalletId')

  React.useEffect(() => {
    if (wallets) {
      setSenderWallets(wallets.filter((wallet) => wallet.id !== receiverWalletId))
      setReceiverWallets(wallets.filter((wallet) => wallet.id !== senderWalletId))
    }
  }, [wallets, senderWalletId, receiverWalletId])

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await axiosInstance.post('/transactions/transfer', {
        amount: Number(String(data.amount).replaceAll(',', '')),
        ledgerAssetId: data.ledgerAssetId,
        receiverWalletId: data.receiverWalletId,
        senderWalletId: data.senderWalletId,
      })

      if (response.status === 201) {
        setIsTransferComplete(true)
        reset()
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const walletFilter = (option: FilterOptionOption<IWallet | any>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='deposit-modal'
      centered
      onHide={() => {
        setIsTransferComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New Transfer</h5>
        </Modal.Title>
      </Modal.Header>
      {isTransferComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Transfer created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsTransferComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            <Form.Group className='mb-3' controlId='amount'>
              <Form.Label>Amount</Form.Label>
              <CurrencyInput
                disabled={isLoading}
                className='form-control'
                defaultValue={0}
                type='text'
                placeholder='Enter amount'
                {...register('amount', { required: true })}
                onChange={(e) => {
                  setValue('amount', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Asset</Form.Label>
              <Select
                options={ledgerAssets}
                placeholder='Select asset'
                isDisabled={isLoading}
                onChange={(option: SingleValue<ILedgerAsset>) => {
                  setValue('ledgerAssetId', option?.id || '')
                }}
              />
              <Form.Text className='text-danger'>
                {errors.ledgerAssetId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Sender Wallet</Form.Label>
              <WalletSelect
                isSearchable={true}
                options={senderWallets}
                placeholder='Select sender wallet'
                isDisabled={isLoading}
                onChange={(option: SingleValue<IWallet> | any) => {
                  setValue('senderWalletId', option?.id)
                }}
                filterOption={walletFilter}
              />
              <Form.Text className='text-danger'>
                {errors.senderWalletId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Receiver Wallet</Form.Label>
              <WalletSelect
                isSearchable={true}
                options={receiverWallets}
                placeholder='Select receiver wallet'
                isDisabled={isLoading}
                onChange={(option: SingleValue<IWallet> | any) => {
                  setValue('receiverWalletId', option?.id)
                }}
                filterOption={walletFilter}
              />
              <Form.Text className='text-danger'>
                {errors.receiverWalletId?.message?.toString()}
              </Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const Transfer = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const [selectedWallet, setSelectedWallet] = React.useState<string>()
  const [showDatePicker, setShowDatePicker] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  })
  const [walletData, setWalletData] = React.useState<IWallet[]>()
  const [showDetailModal, setShowDetailModal] = React.useState(false)
  const [selectedTransfer, setSelectedTransfer] = React.useState<ITransaction>()

  const { data, isLoading, renderPagination, refetch } = usePagination<ITransaction>({
    queryKey: ['getTransactionsTransfer'],
    url: '/transactions',
    queryParams: {
      type: TransactionType.TRANSFER,
      walletId: selectedWallet || '',
      startDate:
        (dateRange.startDate && moment(dateRange.startDate).format('YYYY-MM-DDT00:00:00')) || null,
      endDate:
        (dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DDT23:59:59')) || null,
    },
    handlePageParams: true,
  })

  const handleGetWallets = async () => {
    try {
      const res = await getWalletData({ size: 100 })
      const wallet = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.id,
      }))
      setWalletData(wallet)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  React.useEffect(() => {
    handleGetWallets()
  }, [])

  const handleExportToCSV = async () => {
    try {
      const res = await exportTransactionToCSV({
        type: TransactionType.TRANSFER,
        walletId: selectedWallet || '',
        startDate: dateRange.startDate && moment(dateRange.startDate).format('YYYY-MM-DDT00:00:00'),
        endDate: dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DDT23:59:59'),
      })
      downloadFile(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const downloadFile = (data: any) => {
    const blob = new Blob([data], { type: 'text/csv' })
    const a = document.createElement('a')
    a.download = 'transfers'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Transfers</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <h6 className='m-0'>History</h6>
                  </div>
                  <div className='d-flex gap-2'>
                    <Button
                      variant='primary'
                      className='d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Transfer
                    </Button>
                  </div>
                </div>
                <hr />

                <Row className='mt-2 position-relative'>
                  <Col md={4}>
                    <label>Wallet</label>
                    <WalletSelect
                      isSearchable={true}
                      options={walletData}
                      placeholder='Select wallet'
                      isDisabled={isLoading}
                      onChange={(option: SingleValue<any>) => {
                        setSelectedWallet(option?.value)
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <label>Date</label>
                    <Row>
                      <button
                        className='btn'
                        style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                      >
                        {dateRange.startDate && dateRange.endDate ? (
                          `${moment(dateRange.startDate).format('YYYY-MM-DD')} - ${moment(
                            dateRange.endDate,
                          ).format('YYYY-MM-DD')}`
                        ) : (
                          <span style={{ color: 'hsl(0, 0%, 50%)' }}>Select Date</span>
                        )}
                      </button>
                      {showDatePicker && (
                        <DateRangePicker
                          isShow={showDatePicker}
                          setShow={(val) => setShowDatePicker(val)}
                          onChange={(item: any) => setDateRange(item.selection)}
                          ranges={[dateRange]}
                          className='date-picker-custom'
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <TransferTable
                          handleClickView={(data) => {
                            setSelectedTransfer(data)
                            setShowDetailModal(true)
                          }}
                          list={data}
                        />{' '}
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <TransferModal
        show={modalShow}
        setModalShow={(value: boolean) => setModalShow(value)}
        wallets={walletData}
        onHide={() => {
          setModalShow(false)
          refetch()
        }}
      />
      <TransactionDetailModal
        show={showDetailModal}
        setModalShow={(value: boolean) => setShowDetailModal(value)}
        onHide={() => {
          setShowDetailModal(false)
        }}
        transactionData={selectedTransfer}
      />
    </>
  )
}

export default Transfer
