import React, { useEffect, useRef, useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker as DatePicker, DateRangePickerProps } from 'react-date-range'
import { addDays } from 'date-fns'

interface IDateRangePicker extends DateRangePickerProps {
  isShow: boolean
  setShow: (val: boolean) => void
}

const DateRangePicker: React.FC<IDateRangePicker> = (props) => {
  const { className, isShow, setShow, ...rest } = props
  const ref = useRef<DatePicker | null>(null)

  return (
    <>
      <div
        className='overlay position-fixed top-0 start-0 w-100 h-100 bg-opacity-25 bg-black'
        style={{ zIndex: 1003 }}
        onClick={() => setShow(false)}
      ></div>
      <DatePicker
        showPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        direction='horizontal'
        preventSnapRefocus={true}
        calendarFocus='backwards'
        className={`react-custom-range-picker-wrapper shadow-sm p-0 ${className}`}
        inputRanges={[]}
        {...rest}
      />
    </>
  )
}

export default DateRangePicker
