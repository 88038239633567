import { AxiosResponse } from 'axios'
import { IRequestResetPasswordBody, IResetPasswordBody } from './authType'
import axiosInstance from '../axios'
import axiosNoTokenInstance from '../axiosNoToken'

export const resetPassword = (
  token: string | null,
  body: IResetPasswordBody,
): Promise<AxiosResponse<any>> =>
  axiosNoTokenInstance.post('/auth/reset-password', body, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const requestResetPassword = (
  body: IRequestResetPasswordBody,
): Promise<AxiosResponse<any>> => axiosInstance.post('/auth/request-password-reset', body)

export const getCheckIsValidTokenPasswordReset = (token: string): Promise<AxiosResponse<any>> =>
  axiosNoTokenInstance.get('/auth/reset-password/check-token', {
    headers: { Authorization: 'Bearer ' + token },
  })
