import { Button, Card, Col, Form, Image, Modal, Row, Spinner, Stack } from 'react-bootstrap'
import { IWallet, User } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import Jazzicon from 'react-jazzicon'
import { MdOutlineWebAssetOff } from 'react-icons/md'
import { formatter, generateColorFromString } from '../helper'
import { HiPlus } from 'react-icons/hi'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useState } from 'react'
import { createNewWallet } from '../api/wallet/walletAPI'
import UserSelect from '../components/Select/UserSelect'
import { SingleValue } from 'react-select'
import checked from '../assets/images/checked.png'

const WalletList = (props: { list: IWallet[] }) => {
  const { list } = props
  return (
    <Row style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'auto' }}>
      {list.map((wallet) => (
        <Col key={wallet.id} xs={12} md={6} lg={4} className='mb-3'>
          <Card border='light' className='card-shadow'>
            <Card.Header>
              <Row>
                <Col className='d-flex align-items-center justify-content-center'>
                  <CopyButton text={wallet.id} className='fw-bold' />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Stack gap={2} className='mb-4 mt-2'>
                <div
                  className='wallet-avatar m-auto'
                  style={{
                    backgroundColor: generateColorFromString(wallet.user.username ?? 'member'),
                  }}
                >
                  <span className='fs-4'>
                    {(wallet.user.username ?? 'member').slice(0, 1).toUpperCase()}
                  </span>
                </div>
                <h5 className='m-auto'>{wallet.user.username ?? 'member'}</h5>
              </Stack>
              <div style={{ maxHeight: '120px', overflow: 'auto', minHeight: '120px' }}>
                {wallet.balances.length === 0 && (
                  <Stack
                    className='align-items-center text-muted justify-content-center'
                    gap={2}
                    style={{ height: '120px' }}
                  >
                    <MdOutlineWebAssetOff className='fs-4' />
                    <span className='ms-2'>No asset</span>
                  </Stack>
                )}
                {wallet.balances.map((balance, index) => (
                  <Row
                    key={balance.id}
                    className={`p-2 flex-nowrap ${index % 2 === 0 ? 'bg-light' : 'bg-white'}`}
                  >
                    <Col xs='auto' className='d-flex align-items-center'>
                      <Jazzicon diameter={24} seed={balance.balance} />
                    </Col>
                    <Col>
                      <Stack direction='horizontal' gap={2}>
                        <span>{balance.ledgerAsset.symbol}</span>
                        <span>{formatter(balance.balance)}</span>
                      </Stack>
                    </Col>
                  </Row>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

const Wallet = () => {
  const { data, isLoading, renderPagination, refetch } = usePagination<IWallet>({
    queryKey: ['getWallets'],
    url: '/wallets',
    handlePageParams: true,
    pageSize: 12,
  })
  const [modalShow, setModalShow] = useState(false)
  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Wallets</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      type='button'
                      onClick={(e) => {
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Wallet
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <WalletList list={data} />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <WalletModal
        show={modalShow}
        onHide={() => {
          refetch()
          setModalShow(false)
        }}
      />
    </>
  )
}

export default Wallet

const createWalletSchema = yup.object({
  userId: yup.string().required(),
})

const WalletModal = (props: { show: boolean; onHide: () => void }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateComplete, setIsCreateComplete] = useState(false)

  const { data: users } = usePagination<User>({
    queryKey: ['getUsersWallet'],
    url: '/users',
    handlePageParams: false,
    mapDataFn: (wallets) =>
      wallets.map((wallet: User) => ({
        ...wallet,
        value: wallet.id,
        label: wallet.id,
      })),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(createWalletSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await createNewWallet({ userId: data.userId })

      if (response.status === 201) {
        setIsCreateComplete(true)
        reset()
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='wallet-modal'
      centered
      onHide={() => {
        reset()
        onHide()
        setIsCreateComplete(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='wallet-modal'>
          <h5 className='m-0 fw-bold'>New Wallet</h5>
        </Modal.Title>
      </Modal.Header>
      {isCreateComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Wallet created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsCreateComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='userId'>
              <Form.Label>User Id</Form.Label>
              <UserSelect
                isSearchable={true}
                options={users}
                isDisabled={isLoading}
                onChange={(option: SingleValue<User> | any) => {
                  setValue('userId', option?.id)
                }}
              />

              <Form.Text className='text-danger'>{errors.userId?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}
