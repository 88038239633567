import React, { useState } from 'react'
import {
  Card,
  Col,
  Row,
  Spinner,
  Table,
  Button,
  Form,
  Modal,
  Image,
  Badge,
  InputGroup,
} from 'react-bootstrap'
import { IUser, UserRole } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import { HiPlus } from 'react-icons/hi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'
import axiosInstanceNoToast from '../api/axiosNoToast'
import * as yup from 'yup'
import checked from '../assets/images/checked.png'
import { formatDateWithTimezone } from '../helper'
import { AiOutlineEye } from 'react-icons/ai'
import { BsPencil, BsTrash } from 'react-icons/bs'
import { updateUserData, deleteUserData, toggleSuspendUserData } from '../api/user/user'
import { IUpdateUserBody } from '../api/user/userType'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

const UserTable = (props: { list: IUser[]; onUpdateClick: (data: IUser) => void }) => {
  const { onUpdateClick } = props

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Created At</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={item.id} className='text-nowrap' />
            </td>
            <td>{item.username ?? '-'}</td>
            <td>{item.email ?? '-'}</td>
            <td>
              {' '}
              <Badge bg={item.role === UserRole.ADMIN ? 'primary' : 'success'}>{item.role}</Badge>
            </td>
            <td>{formatDateWithTimezone(item.createdAt)}</td>
            <td>
              <Badge bg={item.suspendedAt === null ? 'success' : 'danger'}>
                {item.suspendedAt === null ? 'Active' : 'Suspended'}
              </Badge>
            </td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  onUpdateClick(item)
                }}
              >
                <BsPencil className='pointer' />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const userSchema = yup
  .object({
    username: yup.string().optional(),
    email: yup.string().optional(),
    role: yup.string().required(),
    password: yup.string().optional(),
  })
  .required()

const userUpdateSchema = yup
  .object({
    role: yup.string().nullable(),
    username: yup.string().optional(),
    email: yup.string().optional().nullable(),
    status: yup.string(),
    oldStatus: yup.string(),
  })
  .required()

const UserModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setModalShow } = props

  const roleOptions = [
    {
      value: 'ADMIN',
      label: 'Admin',
    },
    {
      value: 'MEMBER',
      label: 'Member',
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(userSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await axiosInstanceNoToast.post('/users', {
        username: data.username !== '' ? data.username : undefined,
        email: data.email !== '' ? data.email : undefined,
        role: data.role,
        password: data.password !== '' ? data.password : undefined,
      })

      if (response.status === 201) {
        setIsComplete(true)
        reset()
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.message && Array.isArray(error.response.data.message)) {
          const errArray: string[] = error.response.data.message
          errArray.forEach((err) => {
            const errStringArr = err.split(', ')
            errStringArr.forEach((text) => {
              toast.error(text)
            })
          })
        } else {
          toast.error(error.response?.data.message)
        }
      }
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New User</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>User created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            <Form.Group className='mb-3'>
              <Form.Label>Role</Form.Label>
              <Select
                options={roleOptions}
                placeholder='Select role'
                isDisabled={isLoading}
                onChange={(option: SingleValue<{ value: string }>) => {
                  setValue('role', option?.value || '')
                }}
              />
              <Form.Text className='text-danger'>{errors.role?.message?.toString()}</Form.Text>
            </Form.Group>
            {watch('role') === UserRole.ADMIN && (
              <>
                <Form.Group className='mb-3' controlId='username'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    disabled={isLoading}
                    defaultValue=''
                    type='text'
                    placeholder='Enter name'
                    {...register('username', { required: false })}
                    onChange={(e) => {
                      setValue('username', e.target.value)
                    }}
                  />
                  <Form.Text className='text-danger'>
                    {errors.username?.message?.toString()}
                  </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled={isLoading}
                    defaultValue=''
                    type='email'
                    placeholder='Enter email'
                    {...register('email', { required: false })}
                    onChange={(e) => {
                      setValue('email', e.target.value)
                    }}
                  />
                  <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='user-password-form'>
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      disabled={isLoading}
                      defaultValue=''
                      type='password'
                      placeholder='Enter password'
                      {...register('password', { required: false })}
                      onChange={(e) => {
                        setValue('password', e.target.value)
                      }}
                    />
                    <Button
                      variant='outline-secondary'
                      id='reveal'
                      onClick={(e) => {
                        e.preventDefault()
                        const input = document.getElementById('user-password-form')
                        if (input) {
                          input.setAttribute(
                            'type',
                            input.getAttribute('type') === 'password' ? 'text' : 'password',
                          )
                        }
                      }}
                    >
                      <AiOutlineEye />
                    </Button>
                  </InputGroup>

                  <Form.Text className='text-danger'>
                    {errors.password?.message?.toString()}
                  </Form.Text>
                </Form.Group>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

enum SUSPEND_STATUS {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

const UserUpdateModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  selectedUser?: IUser
  handleSuspendUser: (user: any) => Promise<AxiosResponse<IUser>>
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setModalShow, selectedUser, handleSuspendUser } = props

  const roleOptions = [
    {
      value: 'ADMIN',
      label: 'Admin',
    },
    {
      value: 'MEMBER',
      label: 'Member',
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(userUpdateSchema),
    values: {
      role: selectedUser?.role,
      username: selectedUser?.username,
      email: selectedUser?.email,
      oldStatus: selectedUser?.suspendedAt ? SUSPEND_STATUS.SUSPENDED : SUSPEND_STATUS.ACTIVE,
      status: selectedUser?.suspendedAt ? SUSPEND_STATUS.SUSPENDED : SUSPEND_STATUS.ACTIVE,
    },
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      if (!selectedUser) return

      const body: IUpdateUserBody = {
        email: data.email,
        username: data?.username,
      }

      const response = await updateUserData(selectedUser.id, body)

      if (data.status !== data.oldStatus) {
        const updateStatus = await handleSuspendUser(selectedUser)
        if (updateStatus.status !== 200 && updateStatus.status !== 201) {
          throw new Error('Update Status Failed')
        }
      }

      if (response && response.status === 200) {
        setIsComplete(true)
        reset()
      }
    } catch (error: any) {
      console.log(error, 'erro')
      // ..
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmitOnlyStatus = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      if (!selectedUser) return

      if (data.status !== data.oldStatus) {
        const updateStatus = await handleSuspendUser(selectedUser)
        if (updateStatus.status === 200 || updateStatus.status === 201) {
          setIsComplete(true)
          reset()
        }
      }
    } catch (error: any) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const suspendOptions = [
    {
      label: 'ACTIVE',
      value: SUSPEND_STATUS.ACTIVE,
    },
    {
      label: 'SUSPENDED',
      value: SUSPEND_STATUS.SUSPENDED,
    },
  ]

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Update User</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>User update successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form
          onSubmit={
            (selectedUser?.role !== UserRole.MEMBER && selectedUser?.email === user.email) ||
            selectedUser?.role === UserRole.MEMBER
              ? handleSubmit(onSubmit)
              : handleSubmit(onSubmitOnlyStatus)
          }
        >
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            {(selectedUser?.role !== UserRole.MEMBER && selectedUser?.email === user.email) ||
            selectedUser?.role === UserRole.MEMBER ? (
              (watch('role') === UserRole.ADMIN || watch('role') === UserRole.MEMBER) && (
                <>
                  <Form.Group className='mb-3' controlId='username'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled={isLoading}
                      defaultValue=''
                      type='text'
                      placeholder='Enter name'
                      {...register('username', { required: false })}
                      onChange={(e) => {
                        setValue('username', e.target.value)
                      }}
                    />
                    <Form.Text className='text-danger'>
                      {errors.username?.message?.toString()}
                    </Form.Text>
                  </Form.Group>

                  {watch('role') === UserRole.ADMIN && (
                    <Form.Group className='mb-3' controlId='email'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        disabled={isLoading}
                        defaultValue=''
                        type='email'
                        placeholder='Enter email'
                        {...register('email', { required: false })}
                        onChange={(e) => {
                          setValue('email', e.target.value)
                        }}
                      />
                      <Form.Text className='text-danger'>
                        {errors.email?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  )}
                  {selectedUser?.email !== user.email && (
                    <Form.Group className='mb-3' controlId='username'>
                      <Form.Label>Status</Form.Label>
                      <Select
                        options={suspendOptions}
                        placeholder='Select status'
                        isDisabled={isLoading}
                        value={suspendOptions.find((val) => val.value === watch('status'))}
                        onChange={(option: SingleValue<any>) => {
                          setValue('status', option?.value || '')
                        }}
                      />
                      <Form.Text className='text-danger'>
                        {errors.username?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  )}
                </>
              )
            ) : (
              <Form.Group className='mb-3' controlId='username'>
                <Form.Label>Status</Form.Label>
                <Select
                  options={suspendOptions}
                  placeholder='Select status'
                  isDisabled={isLoading}
                  value={suspendOptions.find((val) => val.value === watch('status'))}
                  onChange={(option: SingleValue<any>) => {
                    setValue('status', option?.value || '')
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.username?.message?.toString()}
                </Form.Text>
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const User = () => {
  const [modalEditShow, setModalEditShow] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser>()
  const [modalShow, setModalShow] = React.useState(false)
  const { data, isLoading, renderPagination, refetch } = usePagination<IUser>({
    queryKey: ['getUsers'],
    url: '/users',
    handlePageParams: true,
  })

  const handleSuspendUser = (user: IUser) => {
    const promise = new Promise((resolve, reject) =>
      toggleSuspendUserData(user.id)
        .then((res: AxiosResponse<IUser>) => {
          resolve(res)
        })
        .catch((err) => reject(err)),
    )
    return promise as Promise<AxiosResponse<IUser>>
  }

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Users</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New User
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <UserTable
                          list={data}
                          onUpdateClick={(user) => {
                            setModalEditShow(true)
                            setSelectedUser(user)
                          }}
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UserModal
        show={modalShow}
        setModalShow={(value: boolean) => setModalShow(value)}
        onHide={() => {
          setModalShow(false)
          refetch()
        }}
      />
      <UserUpdateModal
        show={modalEditShow}
        setModalShow={(value: boolean) => setModalEditShow(value)}
        handleSuspendUser={handleSuspendUser}
        onHide={() => {
          setModalEditShow(false)
          refetch()
        }}
        selectedUser={selectedUser}
      />
    </>
  )
}

export default User
