export enum MarketOrderStatus {
  LISTED = 'LISTED',
  EXECUTED = 'EXECUTED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

export interface IGetMarketOrderParams {
  askLedgerAssetId?: string
  bidLedgerAssetId?: string
  minAskAmount?: number
  minBidAmount?: number
  maxAskAmount?: number
  maxBidAmount?: number
  status?: MarketOrderStatus
  from?: number
  size?: number
}

export interface ICreateMarketOrderBody {
  initiatorWalletId: string
  askLedgerAssetId: string
  askAmount: number
  bidLedgerAssetId: string
  bidAmount: number
  expiredAt: string
}

export interface IMarketOrderUser {
  id: string
  username: string
  email: string
  accountId: string
}

export interface IMarketOrderWallet {
  id: string
  usedId: string
  user: IMarketOrderUser
}

export interface IMarketOrderLedgerAsset {
  id: string
  symbol: string
  accountId: string
}

export interface IMarketOrderResponse {
  id: string
  initiatorWalletId: string
  executorWalletId: string
  askLedgerAssetId: string
  askAmount: number
  bidLedgerAssetId: string
  bidAmount: number
  status: MarketOrderStatus
  createdAt: string
  updatedAt: string
  expiredAt: string
  canceledAt: string
  initiatorWallet?: IMarketOrderWallet
  executorWallet?: IMarketOrderWallet
  askLedgerAsset: IMarketOrderLedgerAsset
  bidLedgerAsset: IMarketOrderLedgerAsset
}

export interface IActionMarketOrderResponse {
  id: string
  initiatorWalletId: string
  executorWalletId: string
  askLedgerAssetId: string
  askAmount: number
  bidLedgerAssetId: string
  bidAmount: number
  status: MarketOrderStatus
  createdAt: string
  updatedAt: string
  expiredAt: string
  canceledAt: string
}

export interface IExecuteMarketOrderBody {
  executorWalletId: string
}
