import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import axiosInstance from '../api/axios'
import { Pagination } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

const PAGE_SIZE = 10

interface UsePaginationProps<T> {
  url: string
  queryParams?: Record<string, string | null | number>
  handlePageParams?: boolean
  pageSize?: number
  mapDataFn?: (data: T[]) => Partial<T>[] | T[]
  queryKey: unknown[]
}

interface UsePaginationReturn<T> {
  isLoading: boolean
  error: unknown
  data: T[]
  handlePageChange: (pageNumber: number) => void
  currentPage: number
  renderPagination: () => JSX.Element
  searchParams: URLSearchParams
  refetch: () => void
}

function usePagination<T>({
  url,
  queryParams,
  handlePageParams,
  pageSize = PAGE_SIZE,
  mapDataFn,
  queryKey,
}: UsePaginationProps<T>): UsePaginationReturn<T> {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [searchParams, setSearchParams] = useSearchParams({ page: '1' })

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [url, page, queryParams, ...queryKey],
    queryFn: () => fetchData(),
    keepPreviousData: true,
    staleTime: 10000,
  })

  const fetchData = async () => {
    const response = await axiosInstance.get(
      `${url}?from=${(page - 1) * pageSize}&size=${pageSize}`,
      {
        params: queryParams,
      },
    )

    setTotalPages(Math.ceil(response.data.totalCount / pageSize))
    if (mapDataFn) {
      return mapDataFn(response.data.data)
    } else {
      return response.data.data
    }
  }

  useEffect(() => {
    if (handlePageParams) {
      setSearchParams({ page: page.toString() })
    }
  }, [page])

  useEffect(() => {
    if (handlePageParams) {
      const pageNumber = parseInt(searchParams.get('page') || '1')
      setPage(pageNumber)
    }
  }, [searchParams])

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const renderPagination = () => {
    return (
      <ReactPaginate
        breakLabel='...'
        nextLabel='>'
        onPageChange={(prop) => handlePageChange(prop.selected + 1)}
        pageRangeDisplayed={3}
        pageCount={totalPages}
        previousLabel='<'
        renderOnZeroPageCount={null}
        className='pagination my-2'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        previousClassName='page-item'
        previousLinkClassName='page-link'
        nextClassName='page-item'
        nextLinkClassName='page-link'
        activeClassName='active'
      />
    )
  }

  return {
    isLoading,
    error,
    data,
    handlePageChange,
    currentPage: page,
    renderPagination,
    searchParams,
    refetch,
  }
}

export default usePagination
