import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BiCopy } from 'react-icons/bi'

function CopyButton(props: { text: string; className?: string }) {
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(props.text)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <>
      <div className={`d-flex align-items-center ${props.className ?? ''}`}>
        <span
          className='fs-6 me-2'
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.text}
        </span>
        <OverlayTrigger show={copied} overlay={<Tooltip id={'tooltip-copy'}>Copied!</Tooltip>}>
          <button onClick={handleClick} className='btn btn-light btn-sm ml-3'>
            <span>
              <BiCopy />
            </span>
          </button>
        </OverlayTrigger>
      </div>
    </>
  )
}

export default CopyButton
