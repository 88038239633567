import { Card, Col, Row, Spinner, Table, Badge, Modal, Form, Button, Image } from 'react-bootstrap'
import { ILedgerAsset, IPrivateToken } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import { formatDateWithTimezone } from '../helper'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useState } from 'react'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import Select, { SingleValue } from 'react-select'
import { HiPlus } from 'react-icons/hi'
import { createNewLedgerAsset, updateLedgerAsset } from '../api/ledgerAsset/ledgerAsset'
import { ICreateLedgerAssetBody, IUpdateLedgerAssetBody } from '../api/ledgerAsset/ledgerAssetType'
import checked from '../assets/images/checked.png'
import { BsPencil } from 'react-icons/bs'

const LedgerAssetsTable = (props: {
  list: ILedgerAsset[]
  onUpdateClick: (data: ILedgerAsset) => void
}) => {
  const { onUpdateClick } = props

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Asset</th>
          <th>Private Token</th>
          <th>Exchange Rate</th>
          <th>Business Account</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((ledgerAsset, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={ledgerAsset.id} className='max-width-300 text-nowrap' />
            </td>
            <td>{ledgerAsset.symbol}</td>
            <td>{ledgerAsset.privateToken.symbol}</td>
            <td>{`${ledgerAsset.exchangeRate} : 1`}</td>
            <td>{ledgerAsset?.account?.name}</td>

            <td>{formatDateWithTimezone(ledgerAsset.createdAt)}</td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  onUpdateClick(ledgerAsset)
                }}
              >
                <BsPencil className='pointer' />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const LedgerAsset = () => {
  const [modalShow, setModalShow] = useState(false)
  const [modalEditShow, setModalEditShow] = useState(false)
  const [selectedLedgerAsset, setSelectedLedgerAsset] = useState<ILedgerAsset>()

  const { data, isLoading, renderPagination, refetch } = usePagination<ILedgerAsset>({
    queryKey: ['getLedgerAssets'],
    url: '/ledger-assets',
    queryParams: {
      withBalance: 'TRUE',
    },
    handlePageParams: true,
  })
  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Ledger Assets</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'></Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Ledger Asset
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <LedgerAssetsTable
                          onUpdateClick={(data) => {
                            setSelectedLedgerAsset(data)
                            setModalEditShow(true)
                          }}
                          list={data}
                        />{' '}
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <LedgerAssetCreateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleConfirm={() => {
          refetch()
        }}
      />
      <LedgerAssetUpdateModal
        show={modalEditShow}
        onHide={() => setModalEditShow(false)}
        handleConfirm={() => {
          refetch()
        }}
        selectedLedgerAsset={selectedLedgerAsset}
      />
    </>
  )
}

export default LedgerAsset

const ledgerAssetCreateSchema = yup.object({
  // exchangeRate: yup.number().required(),
  privateTokenId: yup.string().required(),
  symbol: yup.string().required(),
})

const LedgerAssetCreateModal = (props: {
  show: boolean
  onHide: () => void
  handleConfirm: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const { data: privateTokens } = usePagination<IPrivateToken>({
    queryKey: ['getPrivateTokenLedgerAsset'],
    url: '/private-tokens',
    handlePageParams: false,
    mapDataFn: (wallets) =>
      wallets.map((wallet: IPrivateToken) => ({
        ...wallet,
        value: wallet.id,
        label: wallet.symbol,
      })),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleConfirm, onHide } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(ledgerAssetCreateSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const body: ICreateLedgerAssetBody = {
        exchangeRate: 1,
        privateTokenId: data.privateTokenId,
        symbol: data.symbol,
      }

      const response = await createNewLedgerAsset(body)

      if (response.status === 201) {
        reset()
        handleConfirm()
        setIsComplete(true)
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const customFilter = (option: FilterOptionOption<IPrivateToken>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='ledger-asset-modal'
      centered
      onHide={() => {
        reset()
        onHide()
        setIsComplete(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='ledger-asset-modal'>
          <h5 className='m-0 fw-bold'>New Ledger Asset</h5>
        </Modal.Title>
      </Modal.Header>
      <>
        {isComplete ? (
          <>
            <Modal.Body>
              <div className='d-flex justify-content-center align-items-center flex-column text-center'>
                <Image
                  src={checked}
                  alt='checked'
                  fluid
                  style={{
                    maxWidth: '100px',
                  }}
                  className='mb-4'
                />
                <h6 className='mb-4'>Ledger Asset created successfully</h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant='light'
                onClick={() => {
                  reset()
                  onHide()
                  setIsComplete(false)
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              {isLoading && (
                <div className='overlay-loader'>
                  <Spinner animation='border' variant='primary' />
                </div>
              )}
              <Form.Group className='mb-3' controlId='symbol'>
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  disabled={isLoading}
                  type='text'
                  placeholder='Enter symbol'
                  {...register('symbol', { required: true })}
                  onChange={(e) => {
                    setValue('symbol', e.target.value)
                  }}
                />
                <Form.Text className='text-danger'>{errors.symbol?.message?.toString()}</Form.Text>
              </Form.Group>
              {/* <Form.Group className='mb-3' controlId='exchange-rate'>
                <Form.Label>Exchange Rate</Form.Label>
                <Form.Control
                  disabled={isLoading}
                  defaultValue={0}
                  type='number'
                  placeholder='Enter exchange rate'
                  {...register('exchangeRate', { required: true })}
                  onChange={(e) => {
                    setValue('exchangeRate', e.target.value)
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.exchangeRate?.message?.toString()}
                </Form.Text>
              </Form.Group> */}

              <Form.Group className='mb-3'>
                <Form.Label>Private Token</Form.Label>
                <Select
                  isSearchable={true}
                  options={privateTokens}
                  placeholder='Select private token'
                  isDisabled={isLoading}
                  onChange={(option: SingleValue<IPrivateToken>) => {
                    setValue('privateTokenId', option?.id || '')
                  }}
                  filterOption={customFilter}
                />
                <Form.Text className='text-danger'>
                  {errors.privateTokenId?.message?.toString()}
                </Form.Text>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant='light'
                onClick={() => {
                  reset()
                  onHide()
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' type='submit' disabled={isLoading}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </>
    </Modal>
  )
}

const ledgerAssetUpdateSchema = yup.object({
  symbol: yup.string().required(),
})

const LedgerAssetUpdateModal = (props: {
  selectedLedgerAsset?: ILedgerAsset
  show: boolean
  onHide: () => void
  handleConfirm: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleConfirm, onHide, selectedLedgerAsset } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(ledgerAssetUpdateSchema),
    values: {
      symbol: selectedLedgerAsset?.symbol || '',
    },
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      if (!selectedLedgerAsset) return

      const body: IUpdateLedgerAssetBody = {
        symbol: data.symbol,
      }

      const response = await updateLedgerAsset(selectedLedgerAsset.id, body)

      if (response.status === 200) {
        reset()
        handleConfirm()
        setIsComplete(true)
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='ledger-asset-modal'
      centered
      onHide={() => {
        reset()
        onHide()
        setIsComplete(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='ledger-asset-modal'>
          <h5 className='m-0 fw-bold'>Edit Ledger Asset</h5>
        </Modal.Title>
      </Modal.Header>
      <>
        {isComplete ? (
          <>
            <Modal.Body>
              <div className='d-flex justify-content-center align-items-center flex-column text-center'>
                <Image
                  src={checked}
                  alt='checked'
                  fluid
                  style={{
                    maxWidth: '100px',
                  }}
                  className='mb-4'
                />
                <h6 className='mb-4'>Ledger Asset update successfully</h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant='light'
                onClick={() => {
                  reset()
                  onHide()
                  setIsComplete(false)
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              {isLoading && (
                <div className='overlay-loader'>
                  <Spinner animation='border' variant='primary' />
                </div>
              )}
              <Form.Group className='mb-3' controlId='symbol'>
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  disabled={isLoading}
                  type='text'
                  placeholder='Enter symbol'
                  {...register('symbol', { required: true })}
                  onChange={(e) => {
                    setValue('symbol', e.target.value)
                  }}
                />
                <Form.Text className='text-danger'>{errors.symbol?.message?.toString()}</Form.Text>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant='light'
                onClick={() => {
                  reset()
                  onHide()
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' type='submit' disabled={isLoading}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </>
    </Modal>
  )
}
