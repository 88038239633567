import React, { Component, ComponentPropsWithoutRef } from 'react'

const CustomTextArea: React.FC<ComponentPropsWithoutRef<'textarea'>> = (props) => {
  const ref = React.useRef<HTMLTextAreaElement>(null)
  const { maxLength, ...rest } = props

  return (
    <div className='custom-textarea'>
      <textarea ref={ref} maxLength={maxLength} {...rest}></textarea>
      <span className='sm'>
        {ref.current?.value.length || 0} / {maxLength}
      </span>
    </div>
  )
}

export default CustomTextArea
