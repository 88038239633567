import React, { ChangeEvent, createRef, useEffect, useRef } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
  Badge,
  Image,
  Stack,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import {
  IDeposit,
  ILedgerAsset,
  IPaymentMethodDuitku,
  IPaymentProviderValue,
  IWallet,
} from '../interfaces'
import { HiPlus } from 'react-icons/hi'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axiosInstance from '../api/axios'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import checked from '../assets/images/checked.png'
import Select, { OptionProps, SingleValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import usePagination from '../hooks/usePagination'
import { debounce, formatDateWithTimezone, formatter, getOffsetTimezone } from '../helper'
import WalletSelect from '../components/Select/WalletSelect'
import CurrencyInput from '../components/Input/CurrencyInput'
import { transactionStatusBadgeBackground } from '../helper/color'
import { getWalletData } from '../api/balance/balance'
import PopoverComponent from '../components/PopoverComponent'
import moment from 'moment'
import { BsEye } from 'react-icons/bs'
import { getDuitkuPaymentMethods } from '../api/deposit/deposit'
import { context } from '../stores'
import PaymentMethodSelect from '../components/Select/PaymentMethodSelect'

const DepositTable = (props: { list: IDeposit[]; handleClickView: (data: IDeposit) => void }) => {
  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Amount</th>
          <th>Asset</th>
          <th>Status</th>
          <th style={{ maxWidth: '150px' }}>Wallet Username</th>
          <th>Created By</th>
          <th>Created At ({getOffsetTimezone()})</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((deposit, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={deposit.id} className='max-width-300 text-nowrap' />
            </td>
            <td className='fw-bold text-primary'>{formatter(deposit.amount)}</td>
            <td>{deposit.ledgerAsset.symbol}</td>
            <td>
              <Badge bg={transactionStatusBadgeBackground(deposit.status)}>{deposit.status}</Badge>
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {/* <CopyButton text={deposit.walletId} className='max-width-300 text-nowrap' /> */}
              {deposit.wallet?.user.username}
            </td>
            <td>
              <PopoverComponent
                text={deposit.createdByUser?.email || ''}
                className='max-width-300 text-nowrap'
              />
            </td>
            <td>{formatDateWithTimezone(deposit.createdAt)}</td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickView(deposit)
                }}
              >
                <BsEye />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const DepositlDetailModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  depositData?: IDeposit
}) => {
  const { onHide, depositData } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='deposit-detail-modall'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-detail-modal'>
          <h5 className='m-0 fw-bold'>Deposit Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={depositData?.amount}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Asset</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.ledgerAsset.symbol} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Status</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.status} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Name</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.createdByUser?.username} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Email</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.email} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Account Email</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.createdByUser?.email} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Wallet Username</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.wallet?.user.username} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Wallet ID</Form.Label>
          <Form.Control disabled={true} type='text' value={depositData?.wallet.id} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Time of Request</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(depositData?.createdAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

const DepositLinkModal = (props: {
  show: boolean
  deposit: IDeposit | null
  onHide: () => void
  handleConfirm: () => void
}) => {
  const [copied, setCopied] = React.useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(props.deposit?.paymentLink || '')
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <Modal {...props} size='sm' aria-labelledby='deposit-link-modal' centered onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title id='deposit-link-modal'>
          <h5 className='m-0 fw-bold'>Deposit Created</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
          <Image
            src={checked}
            alt='checked'
            fluid
            style={{
              maxWidth: '100px',
            }}
            className='mb-4'
          />
          <h6 className='mb-4'>Deposit created successfully</h6>
          <div className='d-grid w-100'>
            <Button
              variant='primary'
              onClick={(e) => {
                e.preventDefault()
                window.open(props.deposit?.paymentLink, '_blank', 'noopener,noreferrer')
              }}
            >
              Pay now
            </Button>
            <Stack direction='horizontal' gap={3} className='my-2'>
              <div
                style={{
                  borderBottom: '1px solid #dee2e6',
                  height: '1px',
                  width: '100%',
                }}
              ></div>
              <span className='text-muted'>or</span>
              <div
                style={{
                  borderBottom: '1px solid #dee2e6',
                  height: '1px',
                  width: '100%',
                }}
              ></div>
            </Stack>
            <InputGroup className='mb-3'>
              <Form.Control
                type='text'
                value={props.deposit?.paymentLink}
                disabled
                placeholder='https://'
                aria-label="Recipient's username"
                aria-describedby='btn-copy-link'
                className='fs-7'
              />
              <OverlayTrigger
                show={copied}
                overlay={<Tooltip id={'tooltip-copy'}>Copied!</Tooltip>}
              >
                <Button variant='outline-primary' id='btn-copy-link' onClick={handleClick}>
                  Copy link
                </Button>
              </OverlayTrigger>
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const depositSchema = yup.object({
  walletId: yup.string().required(),
  ledgerAssetId: yup.string().required(),
  amount: yup
    .string()
    .required()
    .test('check-amount', 'minimum amount is 10000', (value, ctx) => {
      const _amount = Number(value.replaceAll(',', ''))
      return _amount >= 10000
    }),
  email: yup.string().email().required(),
  username: yup.string().required(),
  paymentProvider: yup.string(),
  method: yup.string().test('check-payment-method', 'payment method is required', (value, ctx) => {
    const provider = ctx.parent.paymentProvider
    return provider === 'DUITKU' ? (value && value !== '' ? true : false) : true
  }),
  paymentDuration: yup
    .mixed()
    .nullable()
    .test('check-payment-duration', 'payment durations minimum value is 0.1', (value, ctx) => {
      const provider = ctx.parent.paymentProvider
      return provider === 'DURIANPAY' ? (value && Number(value) > 0 ? true : false) : true
    }),
})

export interface IPaymentMethodDuitkuOption extends IPaymentMethodDuitku {
  label: string
  value: string
}

const DepositModal = (props: {
  show: boolean
  onHide: () => void
  handleConfirm: (deposit: IDeposit) => void
  wallets: IWallet[] | undefined
  paymentProvider: IPaymentProviderValue | null
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [duitkuMethods, setDuitkuMethods] = React.useState<IPaymentMethodDuitkuOption[] | null>(
    null,
  )

  const { data: ledgerAssets } = usePagination<ILedgerAsset>({
    queryKey: ['getLedgerAssetsDeposit'],
    url: '/ledger-assets',
    handlePageParams: false,
    pageSize: 100,
    queryParams: {
      withBalance: 'TRUE',
    },
    mapDataFn: (ledgerAssets) =>
      ledgerAssets.map((ledgerAsset: ILedgerAsset) => ({
        ...ledgerAsset,
        value: ledgerAsset.id,
        label: ledgerAsset.symbol,
      })),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleConfirm, onHide, wallets } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(depositSchema),
    defaultValues: {
      paymentDuration: 24,
    },
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await axiosInstance.post('/deposits', {
        email: data.email,
        username: data.username,
        amount: Number(String(data.amount).replaceAll(',', '')),
        ledgerAssetId: data.ledgerAssetId,
        walletId: data.walletId,
        method: data.method || undefined,
        paymentDuration: data.paymentDuration * 3600 || undefined,
      })

      if (response.status === 201) {
        reset()
        handleConfirm(response.data)
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const customFilter = (option: FilterOptionOption<IWallet | any>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  const handleChangeAmmount = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const value = watch('amount').replaceAll(',', '')
      const methods = await getDuitkuPaymentMethods(Number(value))

      const options = methods.data.paymentFee.map((method) => ({
        label: method.paymentName,
        value: method.paymentMethod,
        ...method,
      }))

      setDuitkuMethods(options)
    } catch (error) {
      console.log(error, 'Error')
    }
  }

  const handleSearchDebounce = useRef(
    debounce((handleChangeAmmount) => {
      handleChangeAmmount()
    }, 400),
  )

  useEffect(() => {
    setValue('paymentProvider', props.paymentProvider as string)
  }, [props.paymentProvider, props.show])

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='deposit-modal'
      centered
      onHide={() => {
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New Deposit</h5>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {isLoading && (
            <div className='overlay-loader'>
              <Spinner animation='border' variant='primary' />
            </div>
          )}
          <Form.Group className='mb-3' controlId='amount'>
            <Form.Label>Amount</Form.Label>
            <CurrencyInput
              disabled={isLoading}
              defaultValue={0}
              type='text'
              className='form-control'
              placeholder='Enter amount'
              {...register('amount', { required: true })}
              onChange={(e) => {
                if (props.paymentProvider === 'DUITKU') {
                  handleSearchDebounce.current(handleChangeAmmount)
                  setValue('amount', e.target.value)
                } else {
                  setValue('amount', e.target.value)
                }
              }}
            />
            <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text>
          </Form.Group>

          {props.paymentProvider === 'DUITKU' && (
            <Form.Group className='mb-3'>
              <Form.Label>Method</Form.Label>
              <PaymentMethodSelect
                options={duitkuMethods || []}
                placeholder='Select method'
                isDisabled={isLoading}
                onChange={(option: SingleValue<any>) => {
                  setValue('method', option?.paymentMethod)
                }}
              />

              <Form.Text className='text-danger'>
                {errors.ledgerAssetId?.message?.toString()}
              </Form.Text>
            </Form.Group>
          )}

          <Form.Group className='mb-3'>
            <Form.Label>Asset</Form.Label>
            <Select
              options={ledgerAssets}
              placeholder='Select asset'
              isDisabled={isLoading}
              onChange={(option: SingleValue<ILedgerAsset>) => {
                setValue('ledgerAssetId', option?.id || '')
              }}
            />
            <Form.Text className='text-danger'>
              {errors.ledgerAssetId?.message?.toString()}
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Wallet</Form.Label>
            <WalletSelect
              isSearchable={true}
              options={wallets}
              placeholder='Select wallet'
              isDisabled={isLoading}
              onChange={(option: SingleValue<IWallet> | any) => {
                setValue('walletId', option?.id)
              }}
              filterOption={customFilter}
            />
            <Form.Text className='text-danger'>{errors.walletId?.message?.toString()}</Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              disabled={isLoading}
              type='email'
              placeholder='Enter email'
              {...register('email', { required: true })}
              onChange={(e) => {
                setValue('email', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='username'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              disabled={isLoading}
              type='string'
              placeholder='Enter name'
              {...register('username', { required: true })}
              onChange={(e) => {
                setValue('username', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.username?.message?.toString()}</Form.Text>
          </Form.Group>

          {props.paymentProvider === 'DURIANPAY' && (
            <Form.Group className='mb-3'>
              <Form.Label>Payment Duration (in hours)</Form.Label>
              <Form.Control
                disabled={isLoading}
                type='number'
                min={0}
                step={0.1}
                placeholder='Enter payment duration (in hours)'
                {...(register('paymentDuration'), { required: true })}
                value={(watch('paymentDuration') && Number(watch('paymentDuration'))) || undefined}
                onChange={(e) => {
                  setValue('paymentDuration', e.target.value ? Number(e.target.value) : undefined)
                }}
              />

              <Form.Text className='text-danger'>
                {errors.paymentDuration?.message?.toString()}
              </Form.Text>
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant='light'
            onClick={() => {
              reset()
              onHide()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Deposit = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const [DepositLinkModalShow, setDepositLinkModalShow] = React.useState(false)
  const [selectedDeposit, setSelectedDeposit] = React.useState<IDeposit | null>(null)
  const [selectedWallet, setSelectedWallet] = React.useState<string>()
  const [walletData, setWalletData] = React.useState<IWallet[]>()
  const [showDetailModal, setShowDetailModal] = React.useState(false)
  const [selectedDepositDetail, setSelectedDepositDetail] = React.useState<IDeposit>()

  const ctx = context()

  const { data, isLoading, renderPagination, refetch } = usePagination<IDeposit>({
    queryKey: ['getDeposits'],
    url: '/deposits',
    queryParams: {
      walletId: selectedWallet || '',
    },
    handlePageParams: true,
  })

  const handleGetWallets = async () => {
    try {
      const res = await getWalletData({ size: 100 })
      const wallet = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.id,
      }))
      setWalletData(wallet)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  React.useEffect(() => {
    handleGetWallets()
  }, [])

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Deposits</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>History</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Deposit
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row className='mt-2'>
                  <Col md={4}>
                    <label>Wallet</label>
                    <WalletSelect
                      isSearchable={true}
                      options={walletData}
                      placeholder='Select wallet'
                      isDisabled={isLoading}
                      onChange={(option: SingleValue<any>) => {
                        setSelectedWallet(option?.value)
                      }}
                    />
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <DepositTable
                          handleClickView={(data) => {
                            setSelectedDepositDetail(data)
                            setShowDetailModal(true)
                          }}
                          list={data}
                        />{' '}
                        <div className='d-flex justify-content-end'>{renderPagination()}</div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DepositModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleConfirm={(deposit) => {
          setModalShow(false)
          setSelectedDeposit(deposit)
          setDepositLinkModalShow(true)
        }}
        paymentProvider={ctx.state.paymentGateway}
        wallets={walletData}
      />
      <DepositLinkModal
        show={DepositLinkModalShow}
        deposit={selectedDeposit}
        onHide={() => {
          setDepositLinkModalShow(false)
          refetch()
        }}
        handleConfirm={() => {
          setDepositLinkModalShow(false)
        }}
      />
      <DepositlDetailModal
        show={showDetailModal}
        setModalShow={(value: boolean) => setShowDetailModal(value)}
        onHide={() => {
          setShowDetailModal(false)
        }}
        depositData={selectedDepositDetail}
      />
    </>
  )
}

export default Deposit
