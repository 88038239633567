import { MarketOrderStatus } from '../api/marketOrder/marketOrderType'
import { TransactionStatus, TransactionType } from '../interfaces'

export const transactionBadgeBackground = (type: TransactionType): string => {
  switch (type) {
    case TransactionType.DEPOSIT:
      return 'success'
    case TransactionType.WITHDRAWAL:
      return 'info'
    case TransactionType.TRANSFER:
      return 'purple'
    case TransactionType.CROSS_ACCOUNT_TRANSFER:
      return 'purple'
    case TransactionType.FEE:
      return 'light-purple'
    default:
      return 'success'
  }
}

export const transactionStatusBadgeBackground = (type: TransactionStatus | string): string => {
  switch (type) {
    case TransactionStatus.EXPIRED:
      return 'light-grey'
    case TransactionStatus.FAILED:
      return 'secondary'
    case TransactionStatus.PENDING:
      return 'orange'
    case TransactionStatus.VERIFIED:
      return 'primary'
    default:
      return 'primary'
  }
}

export const marketOrderStatusBadgeBackground = (type: MarketOrderStatus): string => {
  switch (type) {
    case MarketOrderStatus.LISTED:
      return 'purple'
    case MarketOrderStatus.EXECUTED:
      return 'success'
    case MarketOrderStatus.CANCELED:
      return 'danger'
    case MarketOrderStatus.EXPIRED:
      return 'orange'
    default:
      return 'success'
  }
}
