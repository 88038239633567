import React, { Component } from 'react'
import UserAvatar from '../UserAvatar'
import { Stack } from 'react-bootstrap'
import Select, { OptionProps, SingleValue, Props } from 'react-select'
import { IWallet } from '../../interfaces'

const CustomOption = ({ innerProps, data }: { innerProps: OptionProps; data: IWallet }) => (
  <Stack
    {...innerProps}
    direction='horizontal'
    className='p-2 btn btn-outline-primary border-0 no-radius'
    gap={2}
  >
    <UserAvatar username={data.user.username ?? 'member'} />
    <span className='fs-7 fw-bold'>{data?.id}</span>
  </Stack>
)

const WalletSelect: React.FC<Props> = (props) => {
  return (
    <Select
      isSearchable={true}
      placeholder='Select sender wallet'
      components={{ Option: CustomOption as never }}
      {...props}
    />
  )
}

export default WalletSelect
