import React from 'react'
import { AxiosError } from 'axios'
import { Form, Button, Container, Col, Row, Spinner, Alert } from 'react-bootstrap'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/images/logo-vertical.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { IResetPasswordBody } from '../api/auth/authType'
import { getCheckIsValidTokenPasswordReset, resetPassword } from '../api/auth/auth'

// yup schema
const schema = yup
  .object({
    newPassword: yup.string().required(),
  })
  .required()

const ResetPassword = () => {
  const [isPreLoading, setIsPreLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [isTokenValid, setIsTokenValid] = React.useState(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()

  // Token
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')

  // on reset submit
  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)
    setErrorMessage(null)

    try {
      const body: IResetPasswordBody = {
        password: data.newPassword,
      }

      const response = await resetPassword(token, body)

      toast.success('Successfully reset password. Please login!!')
      setTimeout(() => {
        navigate('/login')
      }, 1000)
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.message || 'Something went wrong')
        if (error.response?.data.message && Array.isArray(error.response.data.message)) {
          const errArray: string[] = error.response.data.message
          errArray.forEach((err) => {
            const errStringArr = err.split(', ')
            errStringArr.forEach((text) => {
              toast.error(text)
            })
          })
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetIsValidLink = async () => {
    try {
      const res = await getCheckIsValidTokenPasswordReset(token || '')
      if (res.data) setIsPreLoading(false)
    } catch (error) {
      setIsPreLoading(false)
      console.log(error)
      setIsTokenValid(false)
    }
  }

  React.useEffect(() => {
    handleGetIsValidLink()
  }, [])

  return (
    <Container
      className='d-flex justify-content-center align-items-center'
      style={{ height: '100vh' }}
    >
      {isPreLoading ? (
        <p>Loading....</p>
      ) : isTokenValid ? (
        <Form style={{ width: '400px', marginBottom: '132px' }} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col
              xs={{
                offset: 3,
                span: 6,
              }}
            >
              <Logo />
            </Col>
          </Row>
          <h5 className='text-center mb-4'>Please enter new password to reset password</h5>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter new password'
              {...register('newPassword', { required: true })}
              onChange={(e) => {
                setErrorMessage(null)
                setValue('newPassword', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.newPassword?.message?.toString()}</Form.Text>
          </Form.Group>

          <div className='d-grid gap-2 mt-4'>
            <Button variant='primary' type='submit' disabled={isLoading}>
              {isLoading ? (
                <Spinner animation='border' size='sm' role='status' aria-hidden='true' />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </Form>
      ) : (
        <div style={{ width: '400px', marginBottom: '132px' }} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col
              xs={{
                offset: 3,
                span: 6,
              }}
            >
              <Logo />
            </Col>
          </Row>
          <Row>
            <p className='text-center'>
              Reset password link is no longer valid. Please request the link again if required.
            </p>
          </Row>
        </div>
      )}
    </Container>
  )
}

export default ResetPassword
