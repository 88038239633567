import { AxiosResponse } from 'axios'
import axiosInstance from '../axiosNoToast'
import { ICreateWalletBody, ICreateWalletResponse } from './walletType'
import { IWallet } from '../../interfaces'
import useUserStore from '../../stores/userStore'

export const createNewWallet = (
  body: ICreateWalletBody,
): Promise<AxiosResponse<ICreateWalletResponse>> => axiosInstance.post('/wallets', body)

export const getWalletById = (id: string): Promise<AxiosResponse<IWallet>> =>
  axiosInstance.get(`/wallets/${id}`)
