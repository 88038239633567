import React from 'react'
import { Button } from 'react-bootstrap'
import { generateColorFromString } from '../helper'
import { User } from '../interfaces'

const AvatarButton = ({
  user,
  onClick,
  paymentProvider,
}: {
  user: User
  paymentProvider: string | null
  onClick: React.MouseEventHandler
}) => (
  <Button variant='light' onClick={onClick} className='avatar-button'>
    <div
      className='avatar-icon me-2'
      style={{ backgroundColor: generateColorFromString(user.username ?? 'member') }}
    >
      <span className='fs-5'>{user.username?.slice(0, 1).toUpperCase()}</span>
    </div>
    <div className='avatar-detail'>
      <span className='fs-6 fw-bold'>{user.account.name}</span>
      <span className='fs-7 text-muted'>{user.username}</span>
      <span className='fs-7 text-muted'>{paymentProvider || 'No Payment Gateway'}</span>
    </div>
  </Button>
)

export default AvatarButton
