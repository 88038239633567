import React from 'react'
import { Form, Button, Container, Col, Row, Spinner } from 'react-bootstrap'
import { FieldValues, useForm } from 'react-hook-form'
import { ReactComponent as Logo } from '../assets/images/logo-vertical.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { IRequestResetPasswordBody } from '../api/auth/authType'
import { requestResetPassword } from '../api/auth/auth'

const schema = yup
  .object({
    email: yup.string().email().required(),
    accountId: yup.string().required(),
  })
  .required()

function ForgotPassword() {
  const [isLoading, setIsLoading] = React.useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const body: IRequestResetPasswordBody = {
        email: data.email,
        slug: data.accountId,
      }

      const response = await requestResetPassword(body)

      toast.success('Check your email to reset password')
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Container
      className='d-flex justify-content-center align-items-center'
      style={{ height: '100vh' }}
    >
      <Form style={{ width: '400px', marginBottom: '132px' }} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col
            xs={{
              offset: 3,
              span: 6,
            }}
          >
            <Logo />
          </Col>
        </Row>
        <h5 className='text-center mb-4'>Please enter your email to reset</h5>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            {...register('email', { required: true })}
            onChange={(e) => {
              setValue('email', e.target.value)
            }}
          />
          <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formAccountId'>
          <Form.Label>Account ID</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter account ID'
            {...register('accountId', { required: true })}
            onChange={(e) => {
              setValue('accountId', e.target.value)
            }}
          />
          <Form.Text className='text-danger'>{errors.accountId?.message?.toString()}</Form.Text>
        </Form.Group>

        <div className='d-grid gap-2 mt-4'>
          <Button variant='primary' type='submit' disabled={isLoading}>
            {isLoading ? (
              <Spinner animation='border' size='sm' role='status' aria-hidden='true' />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default ForgotPassword
