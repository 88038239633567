import React, { Component, useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { IWithdrawal } from '../../interfaces'
import moment from 'moment'
import { formatter, getOffsetTimezone } from '../../helper'
import { toast } from 'react-toastify'
import NoData from '../NoData'
import { putPendingAccountWithdrawalByWithdrawalId } from '../../api/withdrawal/withdrawal'
import { IPutPendingAccountWithdrawaltByIdBody } from '../../api/withdrawal/withdrawalType'
import CustomTextArea from '../Input/CustomTextArea'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const commentSchema = yup
  .object({
    comment: yup.string().required(),
  })
  .required()

interface IReviewSidebar {
  show: boolean
  setShow: (val: boolean) => void
  data?: IWithdrawal[]
  setPageData: (val?: number) => void
}

type IPage = 'list' | 'detail'

const WithdrawalReviewSidebar: React.FC<IReviewSidebar> = ({
  show,
  setShow,
  data,
  setPageData,
}) => {
  useEffect(() => {
    if (show === true) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  const [page, setPage] = useState<IPage>('list')
  const [selectedData, setSelectedData] = useState<IWithdrawal>()

  return (
    <>
      <div
        className={`review-sidebar-overlay ${show ? '' : 'review-sidebar-overlay--hidden'}`}
        onClick={() => {
          setShow(false)
          setTimeout(() => {
            setPage('list')
          }, 550)
        }}
      ></div>
      <div className={`review-sidebar ${show ? '' : 'review-sidebar--hidden'}`}>
        <div className='review-sidebar__container'>
          <ReviewSidebarList
            data={data}
            handleItemClick={(data) => {
              setPage('detail')
              setSelectedData(data)
            }}
            setPageData={setPageData}
          />
          <ReviewSidebarDetail
            setPageData={setPageData}
            detailData={selectedData}
            page={page}
            handleBack={() => setPage('list')}
          />
        </div>
      </div>
    </>
  )
}

const ReviewSidebarList: React.FC<{
  data?: IWithdrawal[]
  handleItemClick: (data: any) => void
  setPageData: () => void
}> = ({ handleItemClick, data, setPageData }) => {
  const ref = React.useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    if (ref.current) {
      if (ref.current.scrollHeight - ref.current.scrollTop === ref.current.offsetHeight) {
        setPageData()
      }
    }
  }

  useEffect(() => {
    ref.current?.addEventListener('scroll', handleScroll)
    return () => ref.current?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className='review-sidebar__list'>
      <div className='review-sidebar__list__head'>
        <h1 className='review-sidebar__list__head__title'>Review pending withdrawal</h1>
        <p className='review-sidebar__list__head__sub'>you can review your pending withdrawal</p>
      </div>
      <div ref={ref} className='review-sidebar__list__body-wrapper'>
        <div className='review-sidebar__list__body'>
          <ul className='review-sidebar__list__body__list'>
            {data && data.length > 0 ? (
              data.map((val, key) => (
                <li
                  key={key}
                  className='review-sidebar__list__body__item shadow-sm'
                  onClick={() => handleItemClick(val)}
                >
                  <div className='review-sidebar__list__body__item__left'>
                    <p className='review-sidebar__list__body__item__asset'>
                      {val.ledgerAsset.symbol}
                    </p>
                    <p className='review-sidebar__list__body__item__by'>
                      by <span className='text-info'>{val.createdByUser?.username}</span>
                    </p>
                    <p className='review-sidebar__list__body__item__by'>
                      <span>{val.createdByUser?.account.name}</span>
                    </p>
                    <p className='review-sidebar__list__body__item__by'>
                      <span>{val.createdByUser?.email}</span>
                    </p>
                  </div>
                  <div className='review-sidebar__list__body__item__right'>
                    <p className='review-sidebar__list__body__item__status'>
                      <span className='circle'></span>
                      <span>Pending review</span>
                    </p>
                    <p className='review-sidebar__list__body__item__ammount'>
                      {formatter(val.amount)}
                    </p>
                    <p className='review-sidebar__list__body__item__date'>
                      {moment(val.createdAt).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <div className='pt-5'>
                <NoData />
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const ReviewSidebarDetail: React.FC<{
  detailData?: IWithdrawal
  page: IPage
  handleBack: () => void
  setPageData: (val?: number) => void
}> = ({ page, handleBack, detailData, setPageData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(commentSchema),
  })

  const handleApproveOrRejectWithdrawal = async (
    body: IPutPendingAccountWithdrawaltByIdBody,
    withdrawalId?: string,
  ) => {
    try {
      if (withdrawalId && body) {
        const comment = getValues('comment')

        const _body = {
          ...body,
          comment: comment,
        }

        const res = await putPendingAccountWithdrawalByWithdrawalId(withdrawalId, _body)
        toast.success('Successfully Update Approval Status')
        handleBack()
        setPageData(0)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  return (
    <div
      className={`review-sidebar__detail ${
        page === 'detail' ? 'review-sidebar__detail--show' : ''
      }`}
    >
      <div className='review-sidebar__detail__container'>
        <div className='review-sidebar__detail__head'>
          <button className='review-sidebar__detail__head__nav' onClick={handleBack}>
            <span className=''>
              <BiArrowBack />
              <span>Back</span>
            </span>
          </button>
          <div className='review-sidebar__detail__head__card'>
            <div className='review-sidebar__detail__head__card__header'>
              <p className='review-sidebar__detail__head__card__header__status'>
                <span className='circle'></span>
                <span>Pending review</span>
              </p>
            </div>
            <div className='review-sidebar__detail__head__card__body'>
              <div className='review-sidebar__detail__head__card__body__left'>
                <p className='review-sidebar__detail__head__card__body__left__symbol'>
                  {detailData?.ledgerAsset.symbol}
                </p>
                <p className='review-sidebar__detail__head__card__body__left__ammount'>
                  {detailData?.amount && formatter(detailData?.amount)}
                </p>
              </div>
              <div className='review-sidebar__detail__head__card__body__right'>
                <p className='review-sidebar__detail__head__card__body__right__date'>
                  {moment(detailData?.createdAt).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='review-sidebar__detail__body-wrapper'>
          <div className='review-sidebar__detail__body'>
            <div className='review-sidebar__detail__body__card'>
              <div className='review-sidebar__detail__body__card__header'>Withdrawal Detail</div>
              <div className='review-sidebar__detail__body__card__body'>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>Email</p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {detailData?.email}
                  </p>
                </div>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>
                    Requestor email
                  </p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {detailData?.createdByUser?.email}
                  </p>
                </div>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>
                    Requestor name
                  </p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {detailData?.createdByUser?.username}
                  </p>
                </div>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>
                    Business Account
                  </p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {detailData?.createdByUser?.account.name}
                  </p>
                </div>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>
                    Requestor role
                  </p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {detailData?.createdByUser?.role}
                  </p>
                </div>
                <div className='review-sidebar__detail__body__card__body__item'>
                  <p className='review-sidebar__detail__body__card__body__item__title'>
                    Time of Request
                  </p>
                  <p className='review-sidebar__detail__body__card__body__item__sub'>
                    {`${moment(detailData?.createdAt).format(
                      'DD-MM-YYYY HH:mm:ss',
                    )} (${getOffsetTimezone()})`}
                  </p>
                </div>
              </div>
            </div>
            <div className='review-sidebar__detail__body__comment'>
              <label>
                Comment<span>*</span>
              </label>
              <CustomTextArea
                maxLength={200}
                placeholder='Please input your comment...'
                rows={4}
                {...register('comment', { required: true })}
                value={watch('comment')}
                onChange={(e) => {
                  setValue('comment', e.target.value)
                }}
              />
            </div>
            <div className='review-sidebar__detail__body__action'>
              <button
                disabled={watch('comment') ? false : true}
                onClick={() =>
                  handleApproveOrRejectWithdrawal({ approvalStatus: 'REJECT' }, detailData?.id)
                }
                className='review-sidebar__detail__body__action__button review-sidebar__detail__body__action__reject btn btn-outline-danger'
              >
                Reject
              </button>
              <button
                disabled={watch('comment') ? false : true}
                onClick={() =>
                  handleApproveOrRejectWithdrawal({ approvalStatus: 'APPROVE' }, detailData?.id)
                }
                className='review-sidebar__detail__body__action__button review-sidebar__detail__body__action__approve btn btn-success'
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithdrawalReviewSidebar
