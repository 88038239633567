import React, { useEffect } from 'react'
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg'
import {
  BsArrowLeftRight,
  BsBoxArrowInDown,
  BsBoxArrowInUp,
  BsBoxArrowRight,
  BsCoin,
  BsCurrencyExchange,
} from 'react-icons/bs'
import { BiHomeAlt2, BiTransfer } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { TbWallet } from 'react-icons/tb'
import { IoPersonOutline } from 'react-icons/io5'
import { context } from '../../stores'
import { getPendingAccountDeposit } from '../../api/deposit/deposit'
import { getPendingAccountWithdrawal } from '../../api/withdrawal/withdrawal'

const Sidebar = () => {
  const ctx = context()

  const handleGetPendingDeposit = async () => {
    try {
      const res = await getPendingAccountDeposit()
      ctx.dispatch({
        totalPendingDeposit: res.data.totalCount,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetPendingAccountWithdrawal = async () => {
    try {
      const res = await getPendingAccountWithdrawal()
      ctx.dispatch({
        totalPendingWithdrawals: res.data.totalCount,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetPendingDeposit()
    handleGetPendingAccountWithdrawal()
  }, [])

  return (
    <div className='vertical-menu'>
      <div className='navbar-brandbox'>
        <div className='logo'>
          <Logo />
        </div>
      </div>
      <div className='sidebar-menu'>
        <div className='sidebar-menu-item mb-1'>
          <span className='sidebar-title fs-7 fw-bold'>Overview</span>
          <ul>
            <li>
              <NavLink to={'/dashboard'}>
                <BiHomeAlt2 style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/transactions'}>
                <BiTransfer style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Transactions
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/approval-history'}>
                <BiTransfer style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Approval History
                </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className='sidebar-menu-item mb-2'>
          <span className='sidebar-title fs-7 fw-bold'>Transactions</span>
          <ul>
            <li>
              <NavLink to={'/deposits'}>
                <BsBoxArrowInDown style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Deposits
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/transfers'}>
                <BsBoxArrowRight style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Transfers
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/withdrawals'}>
                <BsBoxArrowInUp style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Withdrawals
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/market-orders'}>
                <BsCurrencyExchange style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Market Orders
                </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className='sidebar-menu-item mb-2'>
          <span className='sidebar-title fs-7 fw-bold'>Account Transactions</span>
          <ul>
            <li>
              <NavLink to={'/account-deposits'}>
                <BsBoxArrowInDown style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Account Deposits
                </span>
                <span className='badge-icon'>{ctx.state.totalPendingDeposit}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/account-transfers'}>
                <BsBoxArrowRight style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Account Transfers
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/account-withdrawals'}>
                <BsBoxArrowInDown style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Account Withdrawals
                </span>
                <span className='badge-icon'>{ctx.state.totalPendingWithdrawals}</span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className='sidebar-menu-item'>
          <span className='sidebar-title fs-7 fw-bold'>Others</span>
          <ul>
            <li>
              <NavLink to={'/users'}>
                <IoPersonOutline style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Users
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/wallets'}>
                <TbWallet style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Wallets
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={'/ledger-assets'}>
                <BsCoin style={{ height: 26 }} />
                <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                  Ledger Assets
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
