import { AxiosResponse } from 'axios'
import {
  IActionMarketOrderResponse,
  ICreateMarketOrderBody,
  IExecuteMarketOrderBody,
} from './marketOrderType'
import axiosInstance from '../axios'

export const createNewMarketOrder = (
  body: ICreateMarketOrderBody,
): Promise<AxiosResponse<IActionMarketOrderResponse>> => axiosInstance.post('/market-orders', body)

export const executeMarketOrder = (
  id: string,
  body: IExecuteMarketOrderBody,
): Promise<AxiosResponse<IActionMarketOrderResponse>> =>
  axiosInstance.post(`/market-orders/${id}/execute`, body)

export const cancelMarketOrder = (id: string): Promise<AxiosResponse<IActionMarketOrderResponse>> =>
  axiosInstance.post(`/market-orders/${id}/cancel`)
