import { AxiosResponse } from 'axios'
import { IWithdrawal } from '../../interfaces'
import {
  IGetPendingAccountWithdrawaltParams,
  IPutPendingAccountWithdrawaltByIdBody,
} from './withdrawalType'
import { IResponseTemplate } from '../types'
import axiosInstance from '../axios'

export const getPendingAccountWithdrawal = (
  params?: IGetPendingAccountWithdrawaltParams,
): Promise<AxiosResponse<IResponseTemplate<IWithdrawal[]>>> =>
  axiosInstance.get('/withdrawals/pending-approval-withdrawals', {
    params: params,
  })

export const putPendingAccountWithdrawalByWithdrawalId = (
  withdrawalId: string,
  body: IPutPendingAccountWithdrawaltByIdBody,
): Promise<AxiosResponse<IResponseTemplate<IWithdrawal[]>>> =>
  axiosInstance.put('/withdrawals/pending-approval-withdrawals/' + withdrawalId, body)
