import { ComponentPropsWithRef, ComponentPropsWithoutRef, PropsWithoutRef, forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

// interface ICurrencyInput extends ComponentPropsWithoutRef<'input'> {}

const CurrencyInput = forwardRef<any, ComponentPropsWithoutRef<'input'>>((props, ref) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  })

  const { value, ...rest } = props

  return <MaskedInput ref={ref} mask={currencyMask} value={value && Number(value)} {...rest} />
})

CurrencyInput.displayName = 'CurrencyInput'

export default CurrencyInput
