// create axios instance

import axios from 'axios'
import { toast } from 'react-toastify'
import useUserStore from '../stores/userStore'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      useUserStore.setState({ isSessionExpired: true })
    } else {
      if (Array.isArray(error.response.data.message)) {
        error.response.data.message = Array.from(new Set(error.response.data.message))
        error.response.data.message.forEach((message: string) => {
          toast(message, { type: 'error' })
        })
      } else {
        toast(error.response.data.message, { type: 'error' })
      }
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
