import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar/Sidebar'

const Home = () => {
  return (
    <div id='main-layout'>
      <Header />
      <Sidebar />
      <div className='main-content'>
        <div className='page-content'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Home
