import { IPaymentProviderValue } from '../interfaces'

export interface GlobalStateType {
  totalPendingDeposit: number
  totalPendingWithdrawals: number
  paymentGateway: IPaymentProviderValue | null
}

// DEFAULT VALUE GLOBAL STATE
export const globalState: GlobalStateType = {
  totalPendingDeposit: 0,
  totalPendingWithdrawals: 0,
  paymentGateway: null,
}
