import { Card, Col, Row, Spinner, Table, Badge, Button, Modal, Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { IApprovalHistory, IWallet, TransactionType } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import { formatDateWithTimezone, formatter, getOffsetTimezone } from '../helper'
import WalletSelect from '../components/Select/WalletSelect'
import { SingleValue } from 'react-select'
import { transactionBadgeBackground, transactionStatusBadgeBackground } from '../helper/color'
import DateRangePicker from '../components/Input/DateRangePicker'
import moment from 'moment'
import { getWalletData } from '../api/balance/balance'
import PopoverComponent from '../components/PopoverComponent'
import TransactionTypeSelect from '../components/Select/TransactionTypeSelect'
import { BsEye } from 'react-icons/bs'
import CurrencyInput from '../components/Input/CurrencyInput'

const ApprovalHistoryTable = (props: {
  list: IApprovalHistory[]
  handleClickView: (data: IApprovalHistory) => void
}) => {
  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Amount</th>
          <th>Asset</th>
          <th>Type</th>
          <th style={{ maxWidth: '150px' }}>Requestor name</th>
          <th style={{ maxWidth: '150px' }}>Wallet Username</th>
          <th style={{ maxWidth: '150px' }}>Business Account</th>
          <th>Requestor Email</th>
          <th>Time of Request ({getOffsetTimezone()})</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((approval, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={approval.id} className='max-width-300 text-nowrap' />
            </td>
            <td className='fw-bold text-primary'>{formatter(approval.amount)}</td>
            <td>{approval.ledgerAsset.symbol}</td>
            <td>
              <Badge bg={transactionStatusBadgeBackground(approval.status)}>
                {approval.status?.toUpperCase()}
              </Badge>
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {approval.createdByUser?.username}
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {approval.wallet?.user.username}
              {/* <CopyButton text={approval.walletId} className='max-width-300 text-nowrap' /> */}
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {approval.createdByUser?.account?.name}
            </td>
            <td>
              <PopoverComponent
                text={approval.createdByUser?.email || ''}
                className='max-width-300 text-nowrap'
              />
            </td>
            <td>{formatDateWithTimezone(approval.createdAt)}</td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickView(approval)
                }}
              >
                <BsEye />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
const ApprovalHistoryDetailModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  approvalHistoryData?: IApprovalHistory
}) => {
  const { onHide, approvalHistoryData } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Approval History Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={approvalHistoryData?.amount}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Asset</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.ledgerAsset.symbol}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Business Account</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.createdByUser?.account?.name}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Type</Form.Label>
          <Form.Control disabled={true} type='text' value={approvalHistoryData?.status} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.createdByUser.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Email</Form.Label>
          <Form.Control disabled={true} type='text' value={approvalHistoryData?.email} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Account Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.createdByUser.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Time of Request</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(approvalHistoryData?.createdAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Reviewer Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.reviewedByUser.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Reviewer Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={approvalHistoryData?.reviewedByUser.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Reviewer Comment</Form.Label>
          <Form.Control
            as='textarea'
            disabled={true}
            type='text'
            value={approvalHistoryData?.reviewerComment}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

const ApprovalHistory = () => {
  const [selectedWallet, setSelectedWallet] = useState<SingleValue<any>>(null)
  const [selectedTransactionType, setSelectedTransactionType] = useState<
    SingleValue<{
      label: string
      value: TransactionType
    }>
  >({
    label: 'DEPOSIT',
    value: TransactionType.DEPOSIT,
  })
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  })
  const [walletData, setWalletData] = useState<IWallet[]>()
  const [selectedApprovalHistory, setSelectedApprovalHistory] = useState<IApprovalHistory>()
  const [showDetailModal, setShowDetailModal] = useState(false)

  const { data, isLoading, renderPagination } = usePagination<IApprovalHistory>({
    queryKey: ['getApprovalHistory'],
    url: '/transactions/approvals',
    handlePageParams: true,
    queryParams: {
      type: selectedTransactionType?.value || '',
      walletId: selectedWallet?.value || '',
      startDate:
        (dateRange.startDate && moment(dateRange.startDate).format('YYYY-MM-DDT00:00:00')) || null,
      endDate:
        (dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DDT23:59:59')) || null,
    },
  })

  const handleGetWallets = async () => {
    try {
      const res = await getWalletData({ size: 100 })
      const wallet = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.id,
      }))
      setWalletData(wallet)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  useEffect(() => {
    handleGetWallets()
  }, [])

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Approval History</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center'>
                    <h6 className='m-0'>History</h6>
                  </div>
                </div>
                <hr />
                <Row className='mt-2 position-relative'>
                  {/* <Col md={4}>
                    <label>Wallet</label>
                    <WalletSelect
                      isSearchable={true}
                      options={walletData}
                      placeholder='Select wallet'
                      isDisabled={isLoading}
                      value={selectedWallet}
                      onChange={(option: SingleValue<any>) => {
                        setSelectedWallet(option)
                      }}
                    />
                  </Col> */}
                  <Col md={4}>
                    <label>Date</label>
                    <div className='d-block'>
                      <button
                        className='btn w-100'
                        style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                      >
                        {dateRange.startDate && dateRange.endDate ? (
                          `${moment(dateRange.startDate).format('YYYY-MM-DD')} - ${moment(
                            dateRange.endDate,
                          ).format('YYYY-MM-DD')}`
                        ) : (
                          <span style={{ color: 'hsl(0, 0%, 50%)' }}>Select Date</span>
                        )}
                      </button>
                      {showDatePicker && (
                        <DateRangePicker
                          isShow={showDatePicker}
                          setShow={(val) => setShowDatePicker(val)}
                          onChange={(item: any) => setDateRange(item.selection)}
                          ranges={[dateRange]}
                          className='date-picker-custom'
                        />
                      )}
                    </div>
                  </Col>
                  <Col md={3}>
                    <label>Transaction Type</label>
                    <TransactionTypeSelect
                      option2={true}
                      isSearchable={true}
                      isDisabled={isLoading}
                      value={selectedTransactionType}
                      onChange={(option: SingleValue<any>) => {
                        setSelectedTransactionType(option)
                      }}
                    />
                  </Col>
                  <Col md={1} className='align-items-end d-flex'>
                    <Button
                      onClick={() => {
                        setDateRange({
                          ...dateRange,
                          startDate: undefined,
                          endDate: undefined,
                        })
                        setSelectedTransactionType({
                          label: 'DEPOSIT',
                          value: TransactionType.DEPOSIT,
                        })
                        setSelectedWallet(null)
                      }}
                      className='mt-auto'
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <ApprovalHistoryTable
                          list={data}
                          handleClickView={(data: IApprovalHistory) => {
                            setSelectedApprovalHistory(data)
                            setShowDetailModal(true)
                          }}
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ApprovalHistoryDetailModal
        show={showDetailModal}
        setModalShow={(value: boolean) => setShowDetailModal(value)}
        onHide={() => {
          setShowDetailModal(false)
        }}
        approvalHistoryData={selectedApprovalHistory}
      />
    </>
  )
}

export default ApprovalHistory
