import React from 'react'
import './App.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Login from './pages/Login'
import NoMatch from './pages/404'
import Home from './pages/Home'
import { toast, ToastContainer } from 'react-toastify'
import Dashboard from './pages/Dashboard'
import Deposit from './pages/Deposit'
import useUserStore from './stores/userStore'
import Transaction from './pages/Transaction'
import Transfer from './pages/Transfer'
import Wallet from './pages/Wallet'
import User from './pages/User'
import LedgerAsset from './pages/LedgerAsset'
import ForgotPassword from './pages/ForgotPassword'
import Withdrawal from './pages/Withdrawal'
import ResetPassword from './pages/ResetPassword'
import CrossTransfer from './pages/CrossTransfer'
import Setting from './pages/Setting'
import AccountDeposit from './pages/AccountDeposit'
import AccountWithdrawal from './pages/AccountWithdrawal'
import ApprovalHistory from './pages/ApprovalHistory'
import MarketOrder from './pages/MarketOrder'

function App() {
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = localStorage.getItem('user')
    const location = useLocation()

    if (!auth) {
      return <Navigate to='/login' state={{ from: location }} replace />
    }

    return children
  }

  const { isSessionExpired, setIsSessionExpired } = useUserStore()

  React.useEffect(() => {
    if (isSessionExpired) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('refreshToken')

      setTimeout(() => {
        toast.warning('Your session has expired. Please login again.')
        setIsSessionExpired(false)
      }, 100)
    }
  }, [isSessionExpired])

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route
          path='/'
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='deposits' element={<Deposit />} />
          <Route path='withdrawals' element={<Withdrawal />} />
          <Route path='transfers' element={<Transfer />} />
          <Route path='approval-history' element={<ApprovalHistory />} />
          <Route path='account-transfers' element={<CrossTransfer />} />
          <Route path='account-deposits' element={<AccountDeposit />} />
          <Route path='account-withdrawals' element={<AccountWithdrawal />} />
          <Route path='transactions' element={<Transaction />} />
          <Route path='ledger-assets' element={<LedgerAsset />} />
          <Route path='wallets' element={<Wallet />} />
          <Route path='users' element={<User />} />
          <Route path='market-orders' element={<MarketOrder />} />
          {/* <Route path='settings' element={<Setting />} /> */}
          <Route path='*' element={<NoMatch />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Routes>
      <ToastContainer pauseOnHover={false} autoClose={2000} />
    </>
  )
}

export default App
