import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
  Badge,
  Image,
  Stack,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { IBank, ILedgerAsset, IPaymentProviderValue, IWallet, IWithdrawal } from '../interfaces'
import { HiPlus } from 'react-icons/hi'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axiosInstance from '../api/axios'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import checked from '../assets/images/checked.png'
import Select, { SingleValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import usePagination from '../hooks/usePagination'
import { formatDateWithTimezone, formatter, getOffsetTimezone } from '../helper'
import WalletSelect from '../components/Select/WalletSelect'
import CurrencyInput from '../components/Input/CurrencyInput'
import { getBanks } from '../api/setting/setting'

import { transactionStatusBadgeBackground } from '../helper/color'
import { getWalletData } from '../api/balance/balance'
import { MdPendingActions } from 'react-icons/md'
import { context } from '../stores'
import WithdrawalReviewSidebar from '../components/Sidebar/WithdrawalReviewSidebar'
import { IGetPendingAccountWithdrawaltParams } from '../api/withdrawal/withdrawalType'
import { getPendingAccountWithdrawal } from '../api/withdrawal/withdrawal'
import moment from 'moment'
import { BsEye } from 'react-icons/bs'

const WithdrawalTable = (props: {
  list: IWithdrawal[]
  handleClickView: (data: IWithdrawal) => void
}) => {
  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Amount</th>
          <th>Asset</th>
          <th>Status</th>
          <th style={{ maxWidth: '150px' }}>Wallet Username</th>
          <th>Email</th>
          <th>Created By</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((withdrawal, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={withdrawal.id} className='max-width-300 text-nowrap' />
            </td>
            <td className='fw-bold text-primary'>{formatter(withdrawal.amount)}</td>
            <td>{withdrawal.ledgerAsset.symbol}</td>
            <td>
              <Badge bg={transactionStatusBadgeBackground(withdrawal.status)}>
                {withdrawal.status}
              </Badge>
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {withdrawal.wallet?.user.username}
              {/* <CopyButton text={} className='max-width-300 text-nowrap' /> */}
            </td>
            <td>{withdrawal.email}</td>
            <td>{withdrawal.createdByUser?.email}</td>
            <td>{formatDateWithTimezone(withdrawal.createdAt)}</td>
            <td>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickView(withdrawal)
                }}
              >
                <BsEye />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const AccountWithdrawalDetailModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  accountWithdrawalData?: IWithdrawal
}) => {
  const { onHide, accountWithdrawalData } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='account-withdrawal-detail-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='account-withdrawal-detail-modal'>
          <h5 className='m-0 fw-bold'>Account Withdrawal Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={accountWithdrawalData?.amount}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Asset</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={accountWithdrawalData?.ledgerAsset.symbol}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Status</Form.Label>
          <Form.Control disabled={true} type='text' value={accountWithdrawalData?.status} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={accountWithdrawalData?.createdByUser?.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Email</Form.Label>
          <Form.Control disabled={true} type='text' value={accountWithdrawalData?.email} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Requestor Account Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={accountWithdrawalData?.createdByUser?.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Wallet Username</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={accountWithdrawalData?.wallet?.user.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Wallet ID</Form.Label>
          <Form.Control disabled={true} type='text' value={accountWithdrawalData?.wallet.id} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Time of Request</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(accountWithdrawalData?.createdAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

const WithdrawalLinkModal = (props: {
  show: boolean
  withdrawal: IWithdrawal | null
  onHide: () => void
  handleConfirm: () => void
  paymentProvider?: IPaymentProviderValue | null
}) => {
  const [copied, setCopied] = React.useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(props.withdrawal?.paymentLink || '')
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='withdrawal-link-modal'
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id='withdrawal-link-modal'>
          <h5 className='m-0 fw-bold'>Account Withdrawal Created</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
          <Image
            src={checked}
            alt='checked'
            fluid
            style={{
              maxWidth: '100px',
            }}
            className='mb-4'
          />
          <h6 className='mb-4'>Account Withdrawal created successfully</h6>
          {props.paymentProvider === 'XENDIT' && (
            <div className='d-grid w-100'>
              <Button
                variant='primary'
                onClick={(e) => {
                  e.preventDefault()
                  window.open(props.withdrawal?.paymentLink, '_blank', 'noopener,noreferrer')
                }}
              >
                Continue to the form
              </Button>
              <Stack direction='horizontal' gap={3} className='my-2'>
                <div
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    height: '1px',
                    width: '100%',
                  }}
                ></div>
                <span className='text-muted'>or</span>
                <div
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    height: '1px',
                    width: '100%',
                  }}
                ></div>
              </Stack>
              <InputGroup className='mb-3'>
                <Form.Control
                  type='text'
                  value={props.withdrawal?.paymentLink}
                  disabled
                  placeholder='https://'
                  aria-label="Recipient's username"
                  aria-describedby='btn-copy-link'
                  className='fs-7'
                />
                <OverlayTrigger
                  show={copied}
                  overlay={<Tooltip id={'tooltip-copy'}>Copied!</Tooltip>}
                >
                  <Button variant='outline-primary' id='btn-copy-link' onClick={handleClick}>
                    Copy link
                  </Button>
                </OverlayTrigger>
              </InputGroup>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

const withdrawalSchema = yup.object({
  walletId: yup.string().required(),
  ledgerAssetId: yup.string().required(),
  amount: yup
    .string()
    .required()
    .test('check-amount', 'minimum amount is 10000', (value, ctx) => {
      const _amount = Number(value.replaceAll(',', ''))
      return _amount >= 10000
    }),
  email: yup.string().email().required(),
  accountNumber: yup
    .number()
    .typeError('you must specify a number')
    .test('account-number-checking', 'Account number is required', (value, context) => {
      const paymentProvider = context.parent.paymentProvider
      return paymentProvider === 'DURIANPAY' || paymentProvider === 'DUITKU'
        ? value && value !== 0
          ? true
          : false
        : true
    }),
  bankCode: yup.string().test('bank-code-checking', 'Bank is required', (value, context) => {
    const paymentProvider = context.parent.paymentProvider
    return paymentProvider === 'DURIANPAY' || paymentProvider === 'DUITKU'
      ? value && value !== ''
        ? true
        : false
      : true
  }),
  paymentProvider: yup.string(),
})

const WithdrawalModal = (props: {
  show: boolean
  onHide: () => void
  handleConfirm: (withdrawal: IWithdrawal) => void
  wallets: IWallet[] | undefined
  paymentProvider?: IPaymentProviderValue | null
  banks?: IBank[]
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const { data: ledgerAssets } = usePagination<ILedgerAsset>({
    queryKey: ['getLedgerAssetsAccountWithdrawal'],
    url: '/ledger-assets',
    handlePageParams: false,
    pageSize: 100,
    queryParams: {
      withBalance: 'TRUE',
      excludeOriginator: 'TRUE',
    },
    mapDataFn: (ledgerAssets) =>
      ledgerAssets.map((ledgerAsset: ILedgerAsset) => ({
        ...ledgerAsset,
        value: ledgerAsset.id,
        label: ledgerAsset.symbol,
      })),
  })

  React.useEffect(() => {
    if (props.paymentProvider) {
      setValue('paymentProvider', props.paymentProvider)
    }
  }, [props.paymentProvider])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleConfirm, onHide, wallets } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(withdrawalSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await axiosInstance.post('/withdrawals', {
        email: data.email,
        amount: Number(String(data.amount).replaceAll(',', '')),
        ledgerAssetId: data.ledgerAssetId,
        walletId: data.walletId,
        bankCode: data.bankCode,
        accountNumber: String(data.accountNumber),
      })

      if (response.status === 201) {
        reset()
        handleConfirm(response.data)
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const customFilter = (option: FilterOptionOption<IWallet | any>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='withdrawal-modal'
      centered
      onHide={() => {
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='withdrawal-modal'>
          <h5 className='m-0 fw-bold'>New Account Withdrawal</h5>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {isLoading && (
            <div className='overlay-loader'>
              <Spinner animation='border' variant='primary' />
            </div>
          )}
          <Form.Group className='mb-3' controlId='amount'>
            <Form.Label>Amount</Form.Label>
            <CurrencyInput
              disabled={isLoading}
              defaultValue={0}
              className='form-control'
              type='text'
              placeholder='Enter amount'
              {...register('amount', { required: true })}
              onChange={(e) => {
                setValue('amount', e.target.value)
              }}
            />
            <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Asset</Form.Label>
            <Select
              options={ledgerAssets}
              placeholder='Select asset'
              isDisabled={isLoading}
              onChange={(option: SingleValue<ILedgerAsset>) => {
                setValue('ledgerAssetId', option?.id || '')
              }}
            />
            <Form.Text className='text-danger'>
              {errors.ledgerAssetId?.message?.toString()}
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Wallet</Form.Label>
            <WalletSelect
              isSearchable={true}
              options={wallets}
              placeholder='Select wallet'
              isDisabled={isLoading}
              onChange={(option: SingleValue<IWallet | any>) => {
                setValue('walletId', option?.id)
              }}
              filterOption={customFilter}
            />
            <Form.Text className='text-danger'>{errors.walletId?.message?.toString()}</Form.Text>
          </Form.Group>

          {(props.paymentProvider === 'DURIANPAY' || props.paymentProvider === 'DUITKU') && (
            <Form.Group className='mb-3' controlId='accountNumber'>
              <Form.Label>Bank Account Number</Form.Label>
              <Form.Control
                disabled={isLoading}
                type='number'
                placeholder='Bank Account number'
                {...register('accountNumber', { required: true })}
                onChange={(e) => {
                  setValue('accountNumber', (e.target.value && Number(e.target.value)) || undefined)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.accountNumber?.message?.toString()}
              </Form.Text>
            </Form.Group>
          )}

          {(props.paymentProvider === 'DURIANPAY' || props.paymentProvider === 'DUITKU') && (
            <Form.Group className='mb-3' controlId='bankCode'>
              <Form.Label>Bank Name</Form.Label>
              <Select
                options={props.banks?.map((bank) => ({
                  ...bank,
                  label: bank.name,
                  value: bank.code,
                }))}
                placeholder='Select bank'
                isDisabled={isLoading}
                onChange={(option: SingleValue<IBank>) => {
                  setValue('bankCode', option?.code)
                }}
              />
              <Form.Text className='text-danger'>{errors.bankCode?.message?.toString()}</Form.Text>
            </Form.Group>
          )}

          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              disabled={isLoading}
              type='email'
              placeholder='Enter email'
              {...register('email', { required: true })}
              onChange={(e) => {
                setValue('email', e.target.value || '')
              }}
            />
            <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant='light'
            onClick={() => {
              reset()
              onHide()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const AccountWithdrawal = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const [WithdrawalLinkModalShow, setWithdrawalLinkModalShow] = React.useState(false)
  const [selectedWithdrawal, setSelectedWithdrawal] = React.useState<IWithdrawal | null>(null)
  const [selectedWallet, setSelectedWallet] = React.useState<string>()
  const [bankList, setBankList] = React.useState<IBank[]>()
  const [walletData, setWalletData] = React.useState<IWallet[]>()
  const [showReviewSidebar, setShowReviewSidebar] = React.useState(false)
  const [pendingData, setPendingData] = React.useState<IWithdrawal[]>()
  const [pendingAccountPage, setPendingAccountPage] = React.useState<number>(0)
  const [isLastPendingDataPage, setIsLastPendingDataPage] = React.useState<boolean>(false)
  const [showDetailModal, setShowDetailModal] = React.useState(false)
  const [selectedAccountWithdrawal, setSelectedAccountWithdrawal] = React.useState<IWithdrawal>()

  const PENDING_DATA_SIZE = 7

  // context
  const ctx = context()

  const { data, isLoading, renderPagination, refetch } = usePagination<IWithdrawal>({
    queryKey: ['getAccountWithdrawal'],
    url: '/withdrawals',
    handlePageParams: true,
    queryParams: {
      walletId: selectedWallet || '',
      showCrossAccount: 'TRUE',
    },
  })

  const getBankData = async () => {
    try {
      const banks = await getBanks()
      setBankList(banks.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetWallets = async () => {
    try {
      const res = await getWalletData({ size: 100 })
      const wallet = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.id,
      }))
      setWalletData(wallet)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleGetPendingAccountWithdrawal = async (
    params?: IGetPendingAccountWithdrawaltParams,
    isRefetch?: boolean,
  ) => {
    try {
      const _pendingWithdrawal = await getPendingAccountWithdrawal({
        from: (isRefetch ? 0 : pendingAccountPage) * PENDING_DATA_SIZE,
        size: PENDING_DATA_SIZE,
        ...params,
      })

      ctx.dispatch({
        totalPendingWithdrawals: _pendingWithdrawal.data.totalCount,
      })

      if (isRefetch) {
        setPendingData(_pendingWithdrawal.data.data)
        return
      }

      if (pendingData && pendingData.length > 0) {
        setPendingData([...pendingData, ..._pendingWithdrawal.data.data])
        if (_pendingWithdrawal.data.data.length < PENDING_DATA_SIZE) {
          setIsLastPendingDataPage(true)
        }
      } else {
        setPendingData(_pendingWithdrawal.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    if (
      ctx.state.paymentGateway === IPaymentProviderValue.DURIANPAY ||
      ctx.state.paymentGateway === IPaymentProviderValue.DUITKU
    ) {
      getBankData()
    }
  }, [ctx.state.paymentGateway])

  React.useEffect(() => {
    handleGetWallets()
  }, [])

  React.useEffect(() => {
    if (pendingAccountPage !== undefined)
      handleGetPendingAccountWithdrawal({
        from: pendingAccountPage * PENDING_DATA_SIZE,
      })
  }, [pendingAccountPage])

  return (
    <>
      <div className='account-deposit'>
        <Row className='g-0 mb-3'>
          <Col className='align-item-center'>
            <h5 className='m-0 fw-bold'>Account Withdrawals</h5>
          </Col>
        </Row>

        <Row className='g-0'>
          <Col>
            <Card border='light' className='card-shadow'>
              <Card.Body>
                <>
                  <Row>
                    <Col className='d-flex align-items-center'>
                      <h6 className='m-0'>History</h6>
                    </Col>
                    <Col>
                      <Button
                        variant='primary'
                        className='float-end d-flex align-items-center'
                        onClick={(e) => {
                          e.preventDefault()
                          setModalShow(true)
                        }}
                      >
                        <span className='d-inline-flex align-items-center'>
                          <HiPlus />
                        </span>
                        &nbsp; New Account Withdrawal
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='d-flex justify-content-end'>
                    <Col md={6} className='d-flex justify-content-end'>
                      <Button
                        variant='primary'
                        className='align-items-center pending-review-button '
                        onClick={() => {
                          setShowReviewSidebar(!showReviewSidebar)
                        }}
                      >
                        <span className='d-inline-flex align-items-center'>
                          <MdPendingActions />
                        </span>
                        &nbsp; Pending Review Withdrawal
                        <span className='badge'>{ctx.state.totalPendingWithdrawals}</span>
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='mt-2'>
                    <Col md={4}>
                      <label>Wallet</label>
                      <WalletSelect
                        isSearchable={true}
                        options={walletData}
                        placeholder='Select wallet'
                        isDisabled={isLoading}
                        onChange={(option: SingleValue<any>) => {
                          setSelectedWallet(option?.value)
                        }}
                      />
                    </Col>
                  </Row>
                  <hr />
                  {isLoading ? (
                    <div className='d-flex justify-content-center align-items-center p-5'>
                      <Spinner animation='border' variant='primary' />
                    </div>
                  ) : (
                    <>
                      {data && data.length > 0 ? (
                        <>
                          <WithdrawalTable
                            handleClickView={(data) => {
                              setSelectedAccountWithdrawal(data)
                              setShowDetailModal(true)
                            }}
                            list={data}
                          />{' '}
                          <div className='d-flex justify-content-end'>{renderPagination()}</div>
                        </>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <WithdrawalModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleConfirm={(withdrawal) => {
            setModalShow(false)
            setSelectedWithdrawal(withdrawal)
            setWithdrawalLinkModalShow(true)
          }}
          wallets={walletData}
          paymentProvider={ctx.state.paymentGateway}
          banks={bankList}
        />
        <WithdrawalLinkModal
          show={WithdrawalLinkModalShow}
          withdrawal={selectedWithdrawal}
          onHide={() => {
            setWithdrawalLinkModalShow(false)
            refetch()
          }}
          handleConfirm={() => {
            setWithdrawalLinkModalShow(false)
          }}
          paymentProvider={ctx.state.paymentGateway}
        />
        <WithdrawalReviewSidebar
          data={pendingData}
          show={showReviewSidebar}
          setShow={(val) => setShowReviewSidebar(val)}
          setPageData={(val?: number) => {
            if (val !== null && val !== undefined) {
              handleGetPendingAccountWithdrawal({}, true)
            } else {
              setPendingAccountPage(pendingAccountPage + 1)
            }
          }}
        />
      </div>
      <AccountWithdrawalDetailModal
        show={showDetailModal}
        setModalShow={(value: boolean) => setShowDetailModal(value)}
        onHide={() => {
          setShowDetailModal(false)
        }}
        accountWithdrawalData={selectedAccountWithdrawal}
      />
    </>
  )
}

export default AccountWithdrawal
