// create 404 page with react-bootstrap

import React from 'react'

const NoMatch = () => {
  return (
    <div>
      <h1>404</h1>
    </div>
  )
}

export default NoMatch
