import React, { useEffect, useState } from 'react'
import { Card, Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { ILedgerAsset, ITransaction, TransactionType } from '../interfaces'
import { getBalanceByLedger, getBlockInfo, getLedgerAssets } from '../api/balance/balance'
import { IBalanceResponse, IBlockResponse } from '../api/balance/balanceType'
import { getTransactionData } from '../api/transaction/transaction'
import { IGetTransactionParams } from '../api/transaction/transactionType'
import { Link } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'
import { formatDateWithTimezone, formatter } from '../helper'

const Dashboard: React.FC = () => {
  const [ledgerValue, setledgerValue] =
    useState<SingleValue<{ label: string; value: string; data: ILedgerAsset }>>()
  const [balanceData, setbalanceData] = useState<IBalanceResponse>()
  const [transactionTransferData, settransactionTransferData] = useState<ITransaction[]>()
  const [transactionDepositData, settransactionDepositData] = useState<ITransaction[]>()
  const [transactionWithdrawData, settransactionWithdrawData] = useState<ITransaction[]>()
  const [blockInfo, setblockInfo] = useState<IBlockResponse>()

  const getLedgerData = async () => {
    return new Promise<{ value: string; label: string; data: ILedgerAsset }[]>((resolve) => {
      getLedgerAssets({ size: 100 }).then((res) => {
        const data = res.data.data.map((ledgerAsset: ILedgerAsset) => ({
          value: ledgerAsset.id,
          label: ledgerAsset.symbol,
          data: ledgerAsset,
        }))
        resolve(data)
      })
    })
  }

  const handleChangeLedgerAssets = async (id: string) => {
    try {
      const res = await getBalanceByLedger(id)
      setbalanceData(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetTransactionsTransfer = async (params?: IGetTransactionParams) => {
    try {
      const res = await getTransactionData({ ...params, type: TransactionType.TRANSFER })
      settransactionTransferData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetTransactionsWithdraw = async (params?: IGetTransactionParams) => {
    try {
      const res = await getTransactionData({ ...params, type: TransactionType.WITHDRAWAL })
      settransactionWithdrawData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetTransactionsDeposit = async (params?: IGetTransactionParams) => {
    try {
      const res = await getTransactionData({ ...params, type: TransactionType.DEPOSIT })
      settransactionDepositData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetBlockData = async () => {
    try {
      const res = await getBlockInfo()
      setblockInfo(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getInitTotalBalance = async () => {
    try {
      const legderData = await getLedgerData()
      if (legderData.length > 0) {
        setledgerValue(legderData[0])
        const balance = await getBalanceByLedger(legderData[0].value)
        setbalanceData(balance.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionsDeposit()
    handleGetTransactionsTransfer()
    handleGetTransactionsWithdraw()
    handleGetBlockData()
    getInitTotalBalance()
  }, [])

  return (
    <div className='dashboard-page'>
      {/* <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h1 className='m-0'>Dashboard</h1>
        </Col>
      </Row> */}
      <Row className='justify-content-center'>
        <Col md={12} lg={9} xl={8} xxl={8}>
          <Row className='justify-content-between mb-3'>
            <Col md={4}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={getLedgerData}
                // placeholder='Select asset'
                // isDisabled={isLoading}
                value={ledgerValue}
                onChange={(
                  option: SingleValue<{ label: string; value: string; data: ILedgerAsset }>,
                ) => {
                  setledgerValue(option)
                  option && handleChangeLedgerAssets(option.value)
                }}
              />
            </Col>
            <Col md={8} className='align-self-end'>
              <p className='text-secondary text-end'>
                <small>
                  Total Blocks Generated {(blockInfo && blockInfo.totalBlock) || 0}
                  <br />
                  (Average block time {(blockInfo && blockInfo.averageBlockTime) || 0} seconds)
                </small>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card border='light' className='card-shadow py-3'>
                <Card.Body>
                  <Row className='justify-content-between'>
                    <Col>
                      <p className='mb-2 text-muted'>Total Balance</p>
                      <h5 className='fw-bold'>
                        {balanceData && balanceData.symbol}{' '}
                        {(balanceData && formatter(balanceData.balance)) || 0}{' '}
                        {ledgerValue && balanceData && (
                          <>
                            <span className='fw-normal'>backed by</span>{' '}
                            {ledgerValue.data.privateToken.symbol + ' '}
                            {formatter(balanceData.balance * ledgerValue.data.exchangeRate) + ' '}
                            <span className='fw-normal'>
                              (Exchange Rate {ledgerValue?.data.exchangeRate}:1)
                            </span>
                          </>
                        )}
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <h4 className='text-w-bold mb-2'>Recent Activities</h4>
              <Tabs
                defaultActiveKey='transfer'
                id='recent-activity-tab-id'
                className='mb-3'
                justify
                transition={false}
              >
                <Tab eventKey='transfer' title='Transfer'>
                  <DashboarActivitiesList
                    type={TransactionType.TRANSFER}
                    data={transactionTransferData}
                  />
                </Tab>
                <Tab eventKey='deposit' title='Deposit'>
                  <DashboarActivitiesList
                    type={TransactionType.DEPOSIT}
                    data={transactionDepositData}
                  />
                </Tab>
                <Tab eventKey='withdraw' title='Withdraw'>
                  <DashboarActivitiesList
                    type={TransactionType.WITHDRAWAL}
                    data={transactionWithdrawData}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard

const DashboarActivitiesList = (props: { data?: ITransaction[]; type: TransactionType }) => {
  const { data, type } = props

  const NotFoundText = () => {
    return type === TransactionType.TRANSFER
      ? 'No Recent Transfer'
      : type === TransactionType.DEPOSIT
      ? 'No Recent Deposit'
      : 'No Recent Withdraw'
  }

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, key) => {
          return (
            <div
              key={key}
              className='d-flex w-100 justify-content-between border-bottom border-1 py-3'
            >
              <div>
                <p className='clamp-text'>
                  Sent{' '}
                  <span className='fw-bold'>
                    {item.ledgerAsset.symbol + ' ' + formatter(item.amount)}
                  </span>{' '}
                  to {item.receiverWalletId}
                </p>
                <p className='text-secondary'>
                  <small>{formatDateWithTimezone(item.createdAt)}</small>
                </p>
              </div>
              <div>
                {type !== TransactionType.TRANSFER && (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='button-tooltip-2' className='p-2'>
                        {item.hash}
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <button
                        ref={ref}
                        {...triggerHandler}
                        className='btn btn-secondary btn-lg text-primary bg-white border-0 rounded-0'
                      >
                        <small>{item.hash.slice(0, 8)}...</small>
                      </button>
                    )}
                  </OverlayTrigger>
                )}
              </div>
            </div>
          )
        })
      ) : (
        <p className='py-3'>{NotFoundText()}</p>
      )}
      {data && data.length > 0 && (
        <div className='text-end mt-4'>
          {type === TransactionType.TRANSFER ? (
            <Link className='text-center w-100' to='/transfers'>
              <span className='mr-3'>See More</span>
              <BsChevronRight />
            </Link>
          ) : type === TransactionType.DEPOSIT ? (
            <Link className='text-center w-100' to='/deposits'>
              <span className='mr-3'>See More</span>
              <BsChevronRight />
            </Link>
          ) : (
            <Link className='text-center w-100' to='/withdrawals'>
              <span className='mr-3'>See More</span>
              <BsChevronRight />
            </Link>
          )}
        </div>
      )}
    </>
  )
}
