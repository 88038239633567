import React, { useState } from 'react'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { BiCopy } from 'react-icons/bi'

function PopoverComponent(props: { text: string; className?: string }) {
  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='top'
        overlay={
          <Popover id='popover-trigger-hover-focus' className='p-2'>
            {props.text}
          </Popover>
        }
      >
        <div
          className={`d-flex align-items-center ${props.className ?? ''} `}
          style={{ maxWidth: 100, cursor: 'pointer' }}
        >
          <span
            className='fs-6 me-2'
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {props.text}
          </span>
        </div>
      </OverlayTrigger>
    </>
  )
}

export default PopoverComponent
