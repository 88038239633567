import React, { useState } from 'react'
import {
  Card,
  Col,
  Row,
  Spinner,
  Table,
  Button,
  Form,
  Modal,
  Stack,
  Image,
  Badge,
} from 'react-bootstrap'
import { ILedgerAsset, IWallet } from '../interfaces'
import NoData from '../components/NoData'
import CopyButton from '../components/CopyButton'
import usePagination from '../hooks/usePagination'
import { HiPlus } from 'react-icons/hi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import * as yup from 'yup'
import checked from '../assets/images/checked.png'
import { formatDateWithTimezone, formatter, getOffsetTimezone } from '../helper'
import WalletSelect from '../components/Select/WalletSelect'
import CurrencyInput from '../components/Input/CurrencyInput'
import DateRangePicker from '../components/Input/DateRangePicker'
import moment from 'moment'
import { getLedgerAssets, getWalletData } from '../api/balance/balance'
import PopoverComponent from '../components/PopoverComponent'
import { BsCheck, BsEye, BsX } from 'react-icons/bs'
import {
  ICreateMarketOrderBody,
  IExecuteMarketOrderBody,
  IGetMarketOrderParams,
  IMarketOrderResponse,
} from '../api/marketOrder/marketOrderType'
import {
  cancelMarketOrder,
  createNewMarketOrder,
  executeMarketOrder,
} from '../api/marketOrder/marketOrder'
import { marketOrderStatusBadgeBackground } from '../helper/color'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import LedgerAsset from './LedgerAsset'
import MarketOrderStatusSelect from '../components/Select/MarketOrderStatusSelect'

const MarketOrderTable = (props: {
  list: IMarketOrderResponse[]
  handleClickView: (data: IMarketOrderResponse) => void
  handleClickExecute: (data: IMarketOrderResponse) => void
}) => {
  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th scope='col' style={{ maxWidth: '150px' }}>
            ID
          </th>
          <th>Ask Amount</th>
          <th>Bid Amount</th>
          <th>Status</th>
          <th style={{ maxWidth: '150px' }}>Ask LedgerAsset</th>
          <th style={{ maxWidth: '150px' }}>Bid LedgerAsset</th>
          <th>Initiator Wallet</th>
          <th>Expired At ({getOffsetTimezone()})</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((marketOrder, index) => (
          <tr key={index}>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              <CopyButton text={marketOrder.id} className='text-nowrap' />
            </td>
            <td className='fw-bold text-primary'>{formatter(marketOrder.askAmount)}</td>
            <td className='fw-bold text-primary'>{formatter(marketOrder.bidAmount)}</td>
            <td>
              <Badge bg={marketOrderStatusBadgeBackground(marketOrder.status)}>
                {marketOrder.status}
              </Badge>
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {marketOrder.askLedgerAsset.symbol}
            </td>
            <td style={{ maxWidth: '150px', paddingRight: '24px' }}>
              {marketOrder.bidLedgerAsset.symbol}
            </td>
            <td>
              <PopoverComponent
                text={marketOrder.initiatorWallet?.user.username || ''}
                className='max-width-300 text-nowrap'
              />
            </td>
            <td>{formatDateWithTimezone(marketOrder.expiredAt)}</td>
            <td className='d-flex'>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickView(marketOrder)
                }}
              >
                <BsEye />
              </Button>
              <Button
                variant='link'
                onClick={() => {
                  props.handleClickExecute(marketOrder)
                }}
              >
                <BsCheck />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const MarketOrderDetailModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  marketOrderData?: IMarketOrderResponse
}) => {
  const { onHide, marketOrderData } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='market-order-detail-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='market-order-detail-modal'>
          <h5 className='m-0 fw-bold'>Market Order Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Bid Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={marketOrderData?.bidAmount}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='amount'>
          <Form.Label>Ask Amount</Form.Label>
          <CurrencyInput
            disabled={true}
            defaultValue={0}
            className='form-control'
            type='text'
            placeholder='Enter amount'
            value={marketOrderData?.askAmount}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Ask Ledger Asset</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.askLedgerAsset.symbol}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Bid Ledger Asset</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.bidLedgerAsset.symbol}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Status</Form.Label>
          <Form.Control disabled={true} type='text' value={marketOrderData?.status} />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Initiator Wallet Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.initiatorWallet?.user.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Initiator Wallet Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.initiatorWallet?.user.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Executor Wallet Name</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.executorWallet?.user.username}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Executor Wallet Email</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={marketOrderData?.executorWallet?.user.email}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Expired At</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(marketOrderData?.expiredAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Canceled At</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={
              marketOrderData?.canceledAt &&
              `${moment(marketOrderData.canceledAt).format(
                'DD-MM-YYYY HH:mm:ss',
              )} (${getOffsetTimezone()})`
            }
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Created At</Form.Label>
          <Form.Control
            disabled={true}
            type='text'
            value={`${moment(marketOrderData?.createdAt).format(
              'DD-MM-YYYY HH:mm:ss',
            )} (${getOffsetTimezone()})`}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  )
}

const marketOrderCreateSchema = yup
  .object({
    askAmount: yup.string().required(),
    askLedgerAssetId: yup.string().required(),
    bidAmount: yup.string().required(),
    bidLedgerAssetId: yup.string().required(),
    initiatorWalletId: yup.string().required(),
    expiredAt: yup.string(),
  })
  .required()

const MarketOrderModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  wallets: IWallet[] | undefined
  ledgerAssets: ILedgerAsset[] | undefined
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMarketOrderComplete, setIsMarketOrderComplete] = React.useState(false)
  const [askLedgerAssets, setAskLedgerAssets] = React.useState<ILedgerAsset[]>([])
  const [bidLedgerAssets, setBidLedgerAssets] = React.useState<ILedgerAsset[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setModalShow, wallets, ledgerAssets } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(marketOrderCreateSchema),
  })

  const askLedgerAsset = watch('askLedgerAssetId')
  const bidLedgerAsset = watch('bidLedgerAssetId')

  React.useEffect(() => {
    if (ledgerAssets) {
      setAskLedgerAssets(ledgerAssets.filter((ledger) => ledger.id !== bidLedgerAsset))
      setBidLedgerAssets(ledgerAssets.filter((ledger) => ledger.id !== askLedgerAsset))
    }
  }, [ledgerAssets, askLedgerAsset, bidLedgerAsset])

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    const _body: ICreateMarketOrderBody = {
      askAmount: Number(data.askAmount.replaceAll(',', '')),
      askLedgerAssetId: data.askLedgerAssetId,
      bidAmount: Number(data.bidAmount.replaceAll(',', '')),
      bidLedgerAssetId: data.bidLedgerAssetId,
      initiatorWalletId: data.initiatorWalletId,
      expiredAt: data.expiredAt,
    }

    try {
      const response = await createNewMarketOrder(_body)

      if (response.status === 201) {
        setIsMarketOrderComplete(true)
        reset()
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const walletFilter = (option: FilterOptionOption<IWallet | any>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='deposit-modal'
      centered
      onHide={() => {
        setIsMarketOrderComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New Market Order</h5>
        </Modal.Title>
      </Modal.Header>
      {isMarketOrderComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Market Order created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsMarketOrderComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            <Form.Group className='mb-3' controlId='askAmount'>
              <Form.Label>Ask Amount</Form.Label>
              <CurrencyInput
                disabled={isLoading}
                className='form-control'
                defaultValue={0}
                type='text'
                placeholder='Enter ask amount'
                {...register('askAmount', { required: true })}
                onChange={(e) => {
                  setValue('askAmount', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.askAmount?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='bidAmount'>
              <Form.Label>Bid Amount</Form.Label>
              <CurrencyInput
                disabled={isLoading}
                className='form-control'
                defaultValue={0}
                type='text'
                placeholder='Enter bid amount'
                {...register('bidAmount', { required: true })}
                onChange={(e) => {
                  setValue('bidAmount', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.bidAmount?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Ask Asset</Form.Label>
              <Select
                options={askLedgerAssets}
                placeholder='Select asset'
                isDisabled={isLoading}
                onChange={(option: SingleValue<ILedgerAsset>) => {
                  setValue('askLedgerAssetId', option?.id || '')
                }}
              />
              <Form.Text className='text-danger'>
                {errors.askLedgerAssetId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Bid Asset</Form.Label>
              <Select
                options={bidLedgerAssets}
                placeholder='Select asset'
                isDisabled={isLoading}
                onChange={(option: SingleValue<ILedgerAsset>) => {
                  setValue('bidLedgerAssetId', option?.id || '')
                }}
              />
              <Form.Text className='text-danger'>
                {errors.bidLedgerAssetId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Wallet</Form.Label>
              <WalletSelect
                isSearchable={true}
                options={wallets}
                placeholder='Select sender wallet'
                isDisabled={isLoading}
                onChange={(option: SingleValue<IWallet> | any) => {
                  setValue('initiatorWalletId', option?.id)
                }}
                filterOption={walletFilter}
              />
              <Form.Text className='text-danger'>
                {errors.initiatorWalletId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Expired At</Form.Label>
              <Form.Control
                type='date'
                {...register('expiredAt', { required: true })}
              ></Form.Control>
              <Form.Text className='text-danger'>{errors.expiredAt?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const marketOrderExecuteSchema = yup
  .object({
    wallet: yup.string().required(),
  })
  .required()

const ExecuteMarketOrderModal = (props: {
  show: boolean
  onHide: () => void
  setModalShow: (value: boolean) => void
  wallets: IWallet[] | undefined
  marketOrderData?: IMarketOrderResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isExecuteMarketOrderComplete, setIsExecuteMarketOrderComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setModalShow, wallets, marketOrderData } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(marketOrderExecuteSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)
    try {
      const _body: IExecuteMarketOrderBody = {
        executorWalletId: data.wallet,
      }

      if (marketOrderData) {
        const response = await executeMarketOrder(marketOrderData?.id, _body)
        if (response.status === 201) {
          setIsExecuteMarketOrderComplete(true)
          reset()
        }
      }
    } catch (error) {
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const walletFilter = (option: FilterOptionOption<IWallet | any>, searchText: string) => {
    // Use a case-insensitive search
    const label = option.data.id.toLowerCase()
    const search = searchText.toLowerCase()
    return label.includes(search)
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='deposit-modal'
      centered
      onHide={() => {
        setIsExecuteMarketOrderComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Execute Market Order</h5>
        </Modal.Title>
      </Modal.Header>
      {isExecuteMarketOrderComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Market Order Executed successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsExecuteMarketOrderComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3'>
              <Form.Label>Wallet</Form.Label>
              <WalletSelect
                isSearchable={true}
                options={wallets}
                placeholder='Select sender wallet'
                isDisabled={isLoading}
                onChange={(option: SingleValue<IWallet> | any) => {
                  setValue('wallet', option?.id)
                }}
                filterOption={walletFilter}
              />
              <Form.Text className='text-danger'>{errors.wallet?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const MarketOrder = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const [executeModalShow, setExecuteModalShow] = React.useState(false)
  const [filter, setFilter] = React.useState<IGetMarketOrderParams | any>({
    status: 'LISTED',
  })
  const [walletData, setWalletData] = React.useState<IWallet[]>()
  const [ledgerAssetData, setLedgerAssetData] = React.useState<ILedgerAsset[]>()
  const [showDetailModal, setShowDetailModal] = React.useState(false)
  const [selectedMarketOrder, setSelectedMarketOrder] = React.useState<IMarketOrderResponse>()

  const { data, isLoading, renderPagination, refetch } = usePagination<IMarketOrderResponse>({
    queryKey: ['getMarketOrders'],
    url: '/market-orders',
    queryParams: filter || {},
    handlePageParams: true,
  })

  const handleGetWallets = async () => {
    try {
      const res = await getWalletData({ size: 100 })
      const wallet = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.id,
      }))
      setWalletData(wallet)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleGetLedgerAssets = async () => {
    try {
      const res = await getLedgerAssets({ size: 100, withBalance: 'TRUE' })
      const ledgerAssets = res.data.data.map((val) => ({
        ...val,
        value: val.id,
        label: val.symbol,
      }))
      setLedgerAssetData(ledgerAssets)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleCancelOrder = (data: IMarketOrderResponse) => {
    Swal.fire({
      title: 'Cancel market order ?',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      confirmButtonColor: '#0084ff',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await cancelMarketOrder(data.id)
          refetch()
          toast.success('Successfully Delete User')
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  React.useEffect(() => {
    handleGetWallets()
    handleGetLedgerAssets()
  }, [])

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>MarketOrders</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <h6 className='m-0'>History</h6>
                  </div>
                  <div className='d-flex gap-2'>
                    <Button
                      variant='primary'
                      className='d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Market Order
                    </Button>
                  </div>
                </div>
                <hr />

                <Row className='mt-2 position-relative'>
                  <Col md={4}>
                    <label>Ask Ledger Asset</label>
                    <Select
                      isSearchable={true}
                      options={ledgerAssetData}
                      placeholder='Select Ledger Asset'
                      isDisabled={isLoading}
                      value={
                        ledgerAssetData?.find((val) => val.id === filter?.askLedgerAssetId) || null
                      }
                      onChange={(option: SingleValue<any>) => {
                        setFilter({
                          ...filter,
                          askLedgerAssetId: option?.value,
                        })
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <label>Bid Ledger Asset</label>
                    <Select
                      isSearchable={true}
                      options={ledgerAssetData}
                      placeholder='Select Ledger Asset'
                      isDisabled={isLoading}
                      value={
                        ledgerAssetData?.find((val) => val.id === filter?.bidLedgerAssetId) || null
                      }
                      onChange={(option: SingleValue<any>) => {
                        setFilter({
                          ...filter,
                          bidLedgerAssetId: option?.value,
                        })
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <label>Status</label>
                    <MarketOrderStatusSelect
                      isSearchable={true}
                      isDisabled={isLoading}
                      value={
                        filter?.status && {
                          label: filter.status,
                          value: filter.status,
                        }
                      }
                      onChange={(option: SingleValue<any>) => {
                        setFilter({
                          ...filter,
                          status: option?.value,
                        })
                      }}
                    />
                  </Col>
                  <Col md={1} className='align-items-end d-flex'>
                    <Button
                      onClick={() => {
                        setFilter({
                          status: 'LISTED',
                        })
                      }}
                      className='mt-auto'
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <MarketOrderTable
                          handleClickView={(data) => {
                            setSelectedMarketOrder(data)
                            setShowDetailModal(true)
                          }}
                          handleClickExecute={(data) => {
                            setSelectedMarketOrder(data)
                            setExecuteModalShow(true)
                          }}
                          list={data}
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MarketOrderModal
        show={modalShow}
        setModalShow={(value: boolean) => setModalShow(value)}
        wallets={walletData}
        ledgerAssets={ledgerAssetData}
        onHide={() => {
          setModalShow(false)
          refetch()
        }}
      />
      <ExecuteMarketOrderModal
        show={executeModalShow}
        setModalShow={(value: boolean) => setExecuteModalShow(value)}
        wallets={walletData}
        marketOrderData={selectedMarketOrder}
        onHide={() => {
          setExecuteModalShow(false)
          refetch()
        }}
      />
      <MarketOrderDetailModal
        show={showDetailModal}
        setModalShow={(value: boolean) => setShowDetailModal(value)}
        onHide={() => {
          setShowDetailModal(false)
        }}
        marketOrderData={selectedMarketOrder}
      />
    </>
  )
}

export default MarketOrder
