import { AxiosResponse } from 'axios'
import { IUser } from '../../interfaces'
import { IUpdateUserBody } from './userType'
import axiosInstance from '../axios'

export const updateUserData = (id: string, body: IUpdateUserBody): Promise<AxiosResponse<IUser>> =>
  axiosInstance.put(`/users/${id}`, body)
export const deleteUserData = (id: string): Promise<AxiosResponse<IUser>> =>
  axiosInstance.delete(`/users/${id}`)
export const toggleSuspendUserData = (id: string): Promise<AxiosResponse<IUser>> =>
  axiosInstance.post(`/users/${id}/suspend-unsuspend`)
