import { AxiosResponse } from 'axios'
import { ICreateLedgerAssetBody, IUpdateLedgerAssetBody } from './ledgerAssetType'
import axiosInstance from '../axios'

export const createNewLedgerAsset = (body: ICreateLedgerAssetBody): Promise<AxiosResponse<any>> =>
  axiosInstance.post('/ledger-assets', body)

export const updateLedgerAsset = (
  id: string,
  body: IUpdateLedgerAssetBody,
): Promise<AxiosResponse<any>> => axiosInstance.put(`/ledger-assets/${id}`, body)
