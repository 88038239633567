import React from 'react'
import empty from '../assets/images/empty.png'
import { Image } from 'react-bootstrap'

const NoData = () => {
  return (
    <div className='d-flex align-items-center w-100 flex-column p-5'>
      <div>
        <Image src={empty} style={{ maxWidth: '100px' }} />
      </div>
      <h6 className='text-muted'>No data found</h6>
    </div>
  )
}

export default NoData
