import { AxiosResponse } from 'axios'
import { IDeposit } from '../../interfaces'
import { IResponseTemplate } from '../types'
import axiosInstance from '../axios'
import {
  IGetPendingAccountDepositParams,
  IPaymentMethodDuitkuResponse,
  IPutPendingAccountDepositByDepositIdBody,
} from './depostType'

export const getPendingAccountDeposit = (
  params?: IGetPendingAccountDepositParams,
): Promise<AxiosResponse<IResponseTemplate<IDeposit[]>>> =>
  axiosInstance.get('/deposits/pending-approval-deposits', {
    params: params,
  })

export const putPendingAccountDepositByDepositId = (
  depositId: string,
  body: IPutPendingAccountDepositByDepositIdBody,
): Promise<AxiosResponse<IResponseTemplate<IDeposit[]>>> =>
  axiosInstance.put('/deposits/pending-approval-deposits/' + depositId, body)

export const getDuitkuPaymentMethods = (
  amount: number,
): Promise<AxiosResponse<IPaymentMethodDuitkuResponse>> =>
  axiosInstance.get('/deposits/methods', {
    params: {
      amount,
    },
  })
