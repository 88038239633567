import { AxiosResponse } from 'axios'
import axiosInstance from '../axios'
import { IBalanceResponse } from '../balance/balanceType'
import { IResponseTemplate } from '../types'
import { ITransaction } from '../../interfaces'
import { IExportTransactionToCSV, IGetTransactionParams } from './transactionType'

export const getTransactionData = (params: IGetTransactionParams) => {
  return new Promise<AxiosResponse<IResponseTemplate<ITransaction[]>>>((resolve, reject) => {
    axiosInstance
      .get('/transactions', { params: params })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const exportTransactionToCSV = (
  params: IExportTransactionToCSV,
): Promise<AxiosResponse<any>> =>
  axiosInstance.get('/transactions/export', {
    params: params,
    headers: {
      'Content-Type': 'text/csv',
    },
  })
