import { Axios, AxiosResponse } from 'axios'
import axiosInstance from '../axios'
import { ISettingResponse } from './settingType'
import { IBank, IPaymentProvider } from '../../interfaces'

export const getSettings = (): Promise<AxiosResponse<ISettingResponse>> =>
  axiosInstance.get('/settings')

export const getBanks = (): Promise<AxiosResponse<IBank[]>> =>
  axiosInstance.get('/withdrawals/bank-list')

export const getPaymentServiceProviderByAccountId = (
  id: string,
): Promise<AxiosResponse<IPaymentProvider>> =>
  axiosInstance.get(`/payment-service-providers/account/${id}`)
