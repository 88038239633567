import React, { Component } from 'react'
import UserAvatar from '../UserAvatar'
import { Stack } from 'react-bootstrap'
import Select, { OptionProps, SingleValue, Props } from 'react-select'
import { IWallet, TransactionType } from '../../interfaces'
import Transaction from '../../pages/Transaction'
import { transactionBadgeBackground } from '../../helper/color'

const CustomOption = ({ innerProps, data }: { innerProps: OptionProps; data: any }) => (
  <Stack
    {...innerProps}
    direction='horizontal'
    className='p-2 btn btn-outline-primary border-0 no-radius'
    gap={2}
  >
    <span className={`fs-7 fw-bold text-${transactionBadgeBackground(data.value)}`}>
      {data?.label}
    </span>
  </Stack>
)

interface ITransactionTypeSelect extends Props {
  option2?: boolean
}

const TransactionTypeSelect: React.FC<ITransactionTypeSelect> = (props) => {
  const options = [
    {
      label: 'DEPOSIT',
      value: 'DEPOSIT',
    },
    {
      label: 'CROSS_ACCOUNT_TRANSFER',
      value: 'CROSS_ACCOUNT_TRANSFER',
    },
    {
      label: 'FEE',
      value: 'FEE',
    },
    {
      label: 'TRANSFER',
      value: 'TRANSFER',
    },
    {
      label: 'WITHDRAWAL',
      value: 'WITHDRAWAL',
    },
  ]

  const options2 = [
    {
      label: 'DEPOSIT',
      value: 'DEPOSIT',
    },
    {
      label: 'WITHDRAWAL',
      value: 'WITHDRAWAL',
    },
  ]

  return (
    <Select
      isSearchable={true}
      placeholder='Select transaction type'
      components={{ Option: CustomOption as never }}
      options={props.option2 ? options2 : options}
      {...props}
    />
  )
}

export default TransactionTypeSelect
