import React from 'react'
import { Stack } from 'react-bootstrap'
import { generateColorFromString } from '../helper'

const UserAvatar = ({ username }: { username: string }) => {
  const color = generateColorFromString(username)
  return (
    <Stack direction='horizontal'>
      <div className='user-avatar me-2' style={{ backgroundColor: color }}>
        <span className='fs-7'>{username?.slice(0, 1).toUpperCase()}</span>
      </div>
      <span className='fs-7 fw-bold text-dark text-opacity-50'>{username}</span>
    </Stack>
  )
}

export default UserAvatar
