import { create } from 'zustand'
import { User } from '../interfaces'

export interface UserStore {
  user: User | null
  isSessionExpired: boolean
  setUser: (user: User) => void
  setIsSessionExpired: (isSessionExpired: boolean) => void
}

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  isSessionExpired: false,
  setUser: (user) => set({ user }),
  setIsSessionExpired: (isSessionExpired) => set({ isSessionExpired }),
}))

export default useUserStore
