export interface User {
  id: string
  createdAt: string
  updatedAt: string
  username: string
  email: string
  role: string
  accountId: string
  account: Account
}

export interface Account {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  slug: string
}

export interface IDeposit {
  id: string
  createdAt: string
  updatedAt: string
  amount: number
  walletId: string
  status: TransactionStatus | string
  ledgerAsset: ILedgerAsset
  paymentLink: string
  createdBy: string
  createdByUser?: IUser
  email?: string
  wallet: IWallet
}

export interface IPaymentMethodDuitku {
  paymentImage: string
  paymentMethod: string
  paymentName: string
  totalFee: string
}

export interface ILedgerAsset {
  id: string
  account: Account
  createdAt: string
  updatedAt: string
  symbol: string
  exchangeRate: number
  accountId: string
  privateTokenId: string
  privateToken: IPrivateToken
}

export interface IPrivateToken {
  createdAt: string
  id: string
  smartContractAddress: string
  symbol: string
  updatedAt: string
}

export interface IWallet {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  user: User
  balances: IBalance[]
}

export interface IBalance {
  id: string
  createdAt: string
  updatedAt: string
  walletId: string
  ledgerAssetId: string
  balance: number
  lastSnapshot: string
  ledgerAsset: ILedgerAsset
}

export interface ITransaction {
  id: string
  createdAt: string
  updatedAt: string
  amount: number
  receiverWalletId: string | null
  senderWalletId: string | null
  ledgerAsset: ILedgerAsset
  depositId: string
  type: TransactionType
  hash: string
  receiverWallet: IWallet
  senderWallet?: IWallet
  createdBy: string
  createdByUser?: IUser
}

export interface IApprovalHistory {
  accountNumber: string
  amount: number
  bankCode: string
  createdAt: string
  createdBy: string
  createdByUser: IUser
  email: string
  expiredAt: string
  externalId: string
  id: string
  ledgerAsset: ILedgerAsset
  ledgerAssetId: string
  paymentServiceProviderId: string
  reviewedBy: string
  reviewedByUser: IUser
  reviewerComment: string
  status: string
  updatedAt: string
  walletId: string
  wallet: IWallet
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  TRANSFER = 'TRANSFER',
  CROSS_ACCOUNT_TRANSFER = 'CROSS_ACCOUNT_TRANSFER',
  FEE = 'FEE',
}

export enum TransactionStatus {
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
}

export interface IUser {
  id: string
  email?: string
  username?: string
  accountId: string
  role: UserRole
  createdAt: string
  updatedAt: string
  wallets?: IWallet[]
  account: Account
  suspendedAt: string | null
}

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export interface IWithdrawal {
  id: string
  createdAt: string
  updatedAt: string
  amount: number
  walletId: string
  status: TransactionStatus | string
  ledgerAsset: ILedgerAsset
  paymentLink: string
  email: string
  createdBy: string
  createdByUser?: IUser
  wallet: IWallet
}

export interface IFee {
  id: string
  createdAt: string
  updatedAt: string
  amount: number
  walletId: string
  enabled: boolean
}

export interface IBank {
  code: string
  name: string
}

export interface IPaymentProvider {
  createdAt: string
  id: string
  provider: IPaymentProviderValue | null
  updatedAt: string
}

export enum IPaymentProviderValue {
  XENDIT = 'XENDIT',
  DURIANPAY = 'DURIANPAY',
  DUITKU = 'DUITKU',
}
